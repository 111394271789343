.fresnel_container_fresnel_greaterThanOrEqual_lg {
    width: 100%;
}

#CiSearch {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 12px;
}

#IoIoClose {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 12px;
}
.sc1qbsdb4 {
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    backdrop-filter: blur(5px);
    align-items: center;  
    -webkit-box-pack: center;
    justify-content: center; 
    position: relative;
    z-index: 3;  
    margin: 0 auto;
    justify-content: space-around;
}

.sc1qgyytsf {
    grid-area: 1 / 1 / 2 / 2;
    margin: 0;
}

.fDDRrD {
    display: flex;
}

.q4DSIt {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 115px;
    height: 60px; 
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
}

.scbdr4 {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.eTcMXG {
    width: unset; 
} 

.mxMD {
    justify-content: center;
    display: flex;
    flex-wrap: wrap; 
    font-size: 1.6rem;
    align-items: center;
    list-style: none;
}

.n2md9 {
    position: relative;
    color: #D5A676;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 1.8rem 2rem;
    text-decoration: none;
    display: block;
}

.n2md9color {
    position: relative;
    color: #D5A676;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 1.8rem 2rem;
    text-decoration: none;
    display: block;
}
 
.meen {
    display: block;
}

.eenm {
    display: none;
}
.n2md9:hover, .n2md9color:hover {
    color: #df903f;
}
.navShow {
    z-index: 100;
    position: fixed;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    top: 0;
    margin: 0 auto;
    padding: 0;
    opacity: 1;
}


.totalPric {
    margin-top: auto;
    width: 100%;
    padding: 1rem;
}

.totalPricnone {
    display: none;
}
.ikSeri {  
    min-height: auto;
    min-width: 320px;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 0;
    transition: all 1s ease;
    width: 100%;
}


.is54tl { 
    margin-top: 1.2em;
    width: 100%;
  cursor: pointer;
  display: flex; 
  outline: none;
  overflow: hidden;
  position: relative;
  transform: none;
  flex-basis: auto;
  flex-shrink: 0; 
  background-color: #D5A676;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-color: transparent;
  justify-content: center;
  padding: 10px;
  color: #fff;
  }
  
  #edbF {
    font-size: 1.2rem;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

   
  .is54tl:focus {
  outline: none;
  }
  
  .is54tl:hover {
  background-color: #df903f;
  text-decoration: none;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  color: #ffffff; 
  transition: all 200ms ease-in-out;
  
  }


.hhQMko {
    grid-area: 1 / 3 / 2 / 3;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-right: -4px;
    gap: 6px;
}

.BpRcta {
    color: #D5A676;
    list-style: none;
    display: flex;
    flex-shrink: 0;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    height: 48px; 
}

.BpRctacolor {
    color: #D5A676;
    list-style: none;
    display: flex;
    flex-shrink: 0;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    height: 48px; 
}

.BpRcta:hover, .BpRctacolor:hover{ 
    color: #df903f;
}

.showCart {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 440px;
    top: 0;
    right: 0;
    z-index: 1042!important;
    visibility: visible;
    transform: translateX(0%); 
    background: white;
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.hideCart {
    display:none;
    position: fixed;
    height: 100%;
    width: 440px;
    top: 0;
    right: 0;
    z-index: 1042!important;
    visibility: hidden;
    transform: translateX(100%);
    transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.openedCart {
    width: 100%;
    height: 100dvh;
    background-color: rgba(92, 91, 91, 0.41);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    overflow: hidden;
}

.closedCart {
    visibility: hidden;
}

.g10fv {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 16px;
    height: 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(45, 41, 38);
    color: rgb(255, 255, 255);
    font-size: 10px;
    border-radius: 4px;
    font-weight: 600;
}
.promSt {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    padding: 12px 16px;
    text-decoration: none;
     border-radius: 1.3rem;
}

.promStz {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 12px;
    color: #D5A676;
    position: relative;
}

.promStzcolor {
    cursor: pointer;
    width: 48px;
    height: 48px;
    padding: 12px;
    color: #D5A676;
    position: relative;
}
.promStz:hover, .promStzcolor:hover {
    color: #df903f;
}
#cart {
    width: 100%;
    height: 100%; 
}

.cartUl {
    padding-left: 0;
    border-bottom: 1px solid #EBEBEB;
}
 
.cartKWX {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    justify-content: center;
}

.cartKXW {
    align-items: start;
    display: flex;
    flex-direction: column; 
    justify-content: start;
}

#emptyText {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: auto;
    text-align: center;
}

// .promStz:after {
//     border-left: 1px solid rgb(93 93 93 / 25%);
//     content: '';
//     height: 2px;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     height: 50%; 
//     transition: 0.2s;
//     top: 50%;
//     transform: translateY(-50%);
// }
.sp180 {
    justify-content: center;
    display: flex;
    align-items: center; v

    span {
        font-size: .9rem; 
        color: #333333;
    }

    img {
        width: 20px;
        height: 20px;
    }
   
}

.sp180:focus {
    span {
        color: unset;
    }
}
 
 
.p16flex {
    list-style: none;  
    padding: 16px;  
    align-items: center;  
    justify-content: space-between;  
    display: flex; 
  }


  
.ult2 {
    display: flex;
    align-items: center;
  }
  
  .mr8ss {
  
    width: 16px;
    height: 14px; 
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;  
    margin-right: 8px;
    top: 0;
  
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #4c4c4c;
      border-radius: 5px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
  
      &:nth-child(1) {
          top: 0;
          width: 100%;
      }
      &:nth-child(4) {
          top: 12px;
          width: 100%; 
          left: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
      }
  
     
      }
    }
   
  
    .p12in {
  
  
      span {
        p{ 
          margin: 0;
          font-size: 20px;
          color: #D5A676;
          font-weight: 400;
        }
      }
    }


.cartButt {
    position: absolute;
    overflow: hidden;
  width: 100%;
  left: 0;
  top: 0;  
  height: 100dvh; 
  background-color: transparent;
  z-index:1041;
  visibility: hidden; 
}

.cartButtOpened {
    position: absolute;
    overflow: hidden;
  width: 100%;
  left: 0;
  top: 0;  
  height: 100dvh; 
  background-color: transparent;
  z-index:1041;
  visibility: visible; 
  transform: translateX(0%); 
}

.closingNavbarMobile {
    width: 100%;
    height: 100dvh;
    background-color: rgba(92, 91, 91, 0.41);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    overflow: hidden;
}
    .exitButtonOnNavbar {   
        color: #333333; 
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    
    .closingTag {
      width: 20px;
      height: 20px; 
    }

@media only screen and (max-width: 1000px) { 

    .BpRcta:nth-child(2) {
        display: none;
    }

    .BpRctacolor {
        display: none;
    }
    .eTcMXG {
        display: none;
    }

    .BpRcta {
        padding-right: 20px;
    }
 
   .sc1qbsdb4 {
    z-index: 3;
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    grid-auto-columns: 1fr;
    grid-gap: 16px 0;
    gap: 16px 0; 
    padding: 12px 5px;
   }

   

   .sc1qgyytsf {
    grid-area: 1 / 2 / 2 / 2;
    display: flex;
    justify-content: center;
   }

   .hhQMko {
    grid-area: 1 / 3 / 2 / 3;
    padding-left: 0;
   }

   .sp180 {
    span {
        display: none;
    }
   }

   .promSt {
    padding: 10px 6px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin: 0px 10px 0px 0px;
   } 


   
   
    }


    @media only screen and (max-width: 600px) {  
        #edbF {
            font-size: 1rem;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

       

        #emptyText {
           font-size: 1.2rem;
        }

       .q4DSIt {
        width: 100px;
        height: 40px;
       }

       .showCart {
        width: 21rem;
       }
    }


    @media only screen and (max-width: 450px) {  
        .showCart {
            width: 80%;
           }
  
     
    }


    @media only screen and (max-width: 400px) { 


        
        .promSt { 
            margin: 0px 5px 0px 0px;
           }
             

         
    }
