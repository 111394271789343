.wrapList { 
    max-width: 950px;
    margin: 2em auto 2em auto;
    
}

.expWid {
    display: flex;
    justify-content: stretch;
    align-items: center;
    border-bottom: solid 0px;
    position: relative;
    padding-left: 2em;
    padding-right: 2em;
}

.summary {
    flex: 1;
    display: flex; 
    color: #fff;
}

.summaryContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 
}

#dobri6 {
    color: #fff;
}

.expandedSummary {
    display: flex;
    align-items: center;
    font-weight: 600; 
    color: #fff;

    svg {
        display: block;
        width: 1em;
        height: 1em;
        font-size: 51px;
        fill: #FEBF00;
    }

    span {
        font-size: 45px;
        line-height: 1;
        overflow: visible;
        margin: 0;
        margin-inline-start: 12px;
    }
}


.summaryText {
    margin-top: 20px;
    font-weight: 400; 
    color: #fff;

    span {
        font-size: 22px;
    line-height: 32px;
    margin: 0;
    }
}
.reviewsDist {
    padding: 0;
    box-shadow: none;
    margin-inline-start: 43px;
}

.progressSection table tr {
    height: 30px;
}

.reviewNum {
    width: 90px;

    ul {
        display: inline-flex;
        margin: unset;
        padding: unset;
        text-indent: unset;
        list-style-type: none;
        gap: 2px; 

        li {
            svg {
                display: block;
                width: 18px;
                height: 18px;
                fill: #FEBF00;
            }
        }
    }
    }
 

    #dobri2 {
        padding: 10px;
    }


.looxProgress {
    width: 17em;
    height: 10px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.04);
}

.looxValue {
    width: 86.12059158134244%; 
    background: #7B7B7B;
    border-radius: 2px;
    height: 10px;
    animation: progressBarGrowAnimation .6s ease forwards;
}


.looxValue2 {
    width: 3.120592%;
    background: #7B7B7B;
    border-radius: 2px;
    height: 10px;
    animation: progressBarGrowAnimation .6s ease forwards;
}

.looxValue3 {
    width: 0%;
    background: #7B7B7B;
    border-radius: 2px;
    height: 10px;
    animation: progressBarGrowAnimation .6s ease forwards;
}
.widgeHeader {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;

    button { 
        margin: 0 auto;
        width: 190px;
        cursor: pointer;
        display: flex;
        outline: none;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transform: none;
        flex-basis: auto;
        flex-shrink: 0;
        padding-top: 10px;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 10px;
        background-color: inherit;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #D5A676;
        background-color: #D5A676;
        color: #fff;
    }

    button:hover{
        background-color: #d9a067;
        color: white;
    }
}

.eiv28s {
    display: flex;
}

.d9df021a {
    display: inline-block;
    position: relative;
    padding: 20px 15px 10px 0;
  }


  .c212ptp {
    margin: 0 auto;
    width: 150px;
    cursor: pointer;
    display: flex;
    outline: none;
    overflow: hidden;
    position: relative;
    transform: none;
    flex-basis: auto;
    flex-shrink: 0;
    padding-top: 5px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5px;
    background-color: #D5A676;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-color: transparent;
}

.c212ptpz {
    margin: 0 auto;
    width: 150px;
    cursor: pointer;
    display: flex;
    outline: none;
    overflow: hidden;
    position: relative;
    transform: none;
    flex-basis: auto;
    flex-shrink: 0;
    padding-top: 5px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5px;
    background-color: #d99e64;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-color: transparent;
}

.c212ptp:focus {
    outline: none;
}

.c212ptp:hover {
    background-color: #D5A676;
    text-decoration: none;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    color: #ffffff; 
    transition: all 200ms ease-in-out;
    
}


.r17s8b7 {
  text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    flex-grow: 1;
    font-size: 1.1em;
    flex-basis: auto;
    flex-shrink: 1;
    font-family: "Larsseit Bold", sans-serif;
    font-weight: 400;
    line-height: 24px;
    padding-left: 10px;
    overflow-wrap: break-word;
    padding-right: 10px;
    letter-spacing: 1px;

    span {
        p {
            margin: 0;
        }
    }
}
 

.c212ptph {
    margin: 0 auto;
    width: 150px;
    cursor: pointer;
    display: flex;
    outline: none;
    overflow: hidden;
    position: relative;
    transform: none;
    flex-basis: auto;
    flex-shrink: 0;
    padding-top: 5px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 5px;
    background-color: inherit;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #D5A676;
}
 
.r17s8b7h {
    text-align: center;
    color: #fff;
    cursor: pointer;
    flex-grow: 1;
    font-size: 1.1em;
    flex-basis: auto;
    flex-shrink: 1;
    font-family: "Larsseit Bold", sans-serif;
    font-weight: 400;
    line-height: 24px;
    padding-left: 10px;
    overflow-wrap: break-word;
    padding-right: 10px;
    letter-spacing: 1px;

    span {
        p {
            margin: 0;
        }
    }
}

.c212ptph:hover {
    background-color: #D5A676FF;
    text-decoration: none;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    color: #ffffff; 
    transition: all 200ms ease-in-out;

    .r17s8b7h {
        color: white;
    }
    
}

.rat962 {
    margin-top: 15px;
    span {
        font-size: 1.1em;
        color: #D5A676;
    }
}

.exp2553 {
    margin-top: 15px;
    span {
        font-size: 1.3em;
        color: #D5A676;
    } 
}

.succ95 {
    span {
        color: #D5A676;
        font-size: 1em;
    }
}

.succ95s {
  display: none;
}

.pic851 { 
        margin-top: 1em;

        input {
            padding-top: 1em;
            padding-bottom: 2em;
            width: 100%;
            color: #fff;
        }
        span {
            font-size: 1em;
            padding: 0 0.4em;
            color: #D5A676;
        }
    }

.nam253t {
    width: 100%;
    padding-left: 4em;
    padding-right: 4em;
    margin: 0 auto;
}
 

    .sa809 {
        padding: 10px;
        display: flex;
        max-width: 1216px;
        margin: 0.5em auto;
        border: 1px solid #D5A676;
        background-color: transparent;
        border-radius: 10px;
    }

.showMore {
    margin: 0 auto;
    width: 187px;
    cursor: pointer;
    display: flex;
    outline: none;
    overflow: hidden;
    position: relative;
    transform: none;
    flex-basis: auto;
    flex-shrink: 0;
    padding-top: 10px;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 10px;
    background-color: inherit;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #D5A676;
    margin-top: 2em;
    justify-content: center;
    color: #fff;
}

.showMore:focus {
    outline: none;
}

.showMore:hover {
    background-color: #da9d61;
    text-decoration: none;
    transition: 0.1s;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    color: #ffffff; 
    transition: all 200ms ease-in-out;
    
}

.nam4912 {
    margin-bottom: 100px;
}


.img52re {
    padding-left: 40px;
    order: 2;
    img {
        width: 180px;
        height: 180px;
        background-color: #EBEBEB;
        border-radius: 4px;
    }
}

.inf89s {
    width: 100%;

    h3 {
        font-size: 1em;
        color: #D5A676;
    }

    p {
        margin-top: 1em;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 19px;
        white-space: pre-wrap;
        color: #fff;
    }
}

.reviewNum4 {
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #D5A676;
    border-top: 1px solid #D5A676;

    ul {
        position: relative;
        top: 3px;
        display: inline-flex;
        margin: unset;
        padding: unset;
        text-indent: unset;
        list-style-type: none;
        gap: 2px; 

        li {
            svg {
                display: block;
                width: 18px;
                height: 18px;
                fill: #FEBF00;
            }
        }
    }
    }
 
    .img52ret {
        display: none;

    }

@media only screen and (max-width: 800px){

    .nam253t { 
        padding-left: 2em;
        padding-right: 2em; 
    }

    .pic851 {  

        input { 
            padding-bottom: 4em;
        }
    }
    .expWid {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        padding-left: 1em;
        padding-right: 1em;
    }
    
    .summary {
        display: flex;
        flex-direction: column;
    }

    .widgeHeader {
        width: 100%;
        flex-direction: row-reverse;
        margin-block-start: 25px;
    }

    .reviewsDist {
        margin: 0;
    }

    .widgeHeader {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        display: block;
        margin-block-start: 0;
        margin-top: 20px;
    }

    .looxProgress {
        width: 25em;
    }

    .expandedSummary { 
    
        svg { 
            font-size: 30px; 
        }
    
        span {
            font-size: 1.6em; 
        }
    }
    
    .summaryText {
        margin-top: 10px; 
    
        span {
            font-size: 1.2em; 
        }
    }

    .progressSection table tr td {
       font-size: 0.9em;
    }

    .d9df021a {
        width: 150px; 
        padding: 0px 0 10px 0;
        margin-right: 15px;
      }
 
  

    .r17s8b7 {
     font-size: 14px;
    }

    .r17s8b7h {
        font-size: 14px;
       }
   
}


@media only screen and (max-width: 600px){

    .succ95 {
        margin-left: 11.25px; 
    }

 .looxProgress {
    width: 15em;
 }

 .d9df021a {
    margin-right: 0;
 }
 .c212ptp {
    
    width: 85%;  
}

.c212ptph {
    
    width: 85%;  
}
}

@media only screen and (max-width: 800px){

    .nam253t { 
        padding-left: 1em;
        padding-right: 1em; 
    }

    
.img52re {
    width: auto;
    padding-left: 15px;
    order: 2;
    height: auto;
    img {
        object-fit: contain;
        border-radius: 4px;
        width: 100%;
        max-height: 215px;
        height: inherit;
    }
}

}



@media only screen and (max-width: 450px){
    .looxProgress {
       width: 9em;
    }

    .sa809 {
        padding: 5px;
    }
   }