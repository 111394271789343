#cover {
    width: 100%;
    position: relative;  
    
  transition: 0.4s;
  }

#coverWidth {
  width: 0;
  position: relative;
}
  .otRUp {
    color: #262627;    
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    margin: 0;
    padding: 0;
    text-align: left;
  }


  
  
  #bsSearch {
    height: 24px;
    width: 24px;
    color: white;
  }


  .searchInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    color: #262627; 
    font: inherit;
    height: 44px;
    height: 44px;
    padding: 16px;
    width: 100%;
  }
  
  
  .searchInput:focus {
    border-color: none;
    box-shadow: none;
    outline: none;
  } 


  
  .searchInputN {
   display: none;
  }
  
  
  .searchInputN:focus {
    border-color: none;
    box-shadow: none;
    outline: none;
  } 

.searchIcon {   
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background:#D5A676;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 0px;
    top: 1px;
    z-index: 1;
    border: none; 
} 

.searchIconN {    
  display: none; 
} 

.bbForm {
  position: absolute;
  right: 0;
    display: flex;
    line-height: 1em;
    margin: 0; 
    width: 300px;
    border: 1px solid rgba(224,224,224, 0.8);
    background-color: rgba(246,248,249, 1);
    align-items: center;
    border-radius: 8px 24px 24px 8px;
}



.bbForm:focus-within {
    border-color: rgba(255,69,0, 1);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
-moz-box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
    outline: medium none currentColor;
}


.bbFormN {
  display: none;
}

.bbFormMobile {
  position: absolute; 
    display: flex;
    line-height: 1em;
    margin: 0; 
    width: 100%;
    border: 1px solid rgba(224,224,224, 0.8);
    background-color: rgba(246,248,249, 1);
    align-items: center;
    border-radius: 8px;
}

.searchInputMobile {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    color: #262627; 
    font: inherit;
    height: 44px;
    height: 44px;
    padding: 16px;
    width: 100%;
    outline: none;
}

@media only screen and (max-width: 1000px) {
  #cover {
    width: 100%;
    position: relative;
    transition: 0.4s;
    height: 45px;
  }

  .otRUp {
    width: 100%; 
  }
  }