.froEnt {
    background: rgb(56,51,53);
background: radial-gradient(circle, rgba(56,51,53,1) 0%, rgba(0,0,0,1) 100%);
    padding-left: 1rem;
    padding-right: 1rem;
}

.g14129 {
   display: flex;
   align-items: center;
   padding-top: 2rem;
   position: relative;
   max-width: 1216px;
   margin: 0 auto;
   padding-left: 1rem;
   padding-right: 1rem;
} 

.bsalt {
    font-weight: 500;
    color: #D5A676;
    line-height: 23.17px;
    font-size: 1.2rem;
    letter-spacing: 0;
    display: block; 
    text-transform: uppercase;
    margin-top: 5rem;
}
.tre5 {
    pointer-events: none;
    min-width: 300px;
        align-items: start;
        position: absolute;
        z-index: 2;
        height: 100%;
        top: 5rem;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 5;
}

.lg4Le {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    padding: 60px 24px 30px 24px;
  }
  
  .glu8en { 
      font-size: 2rem;
      color: #fff; 
      position: relative;
  }
  
  .glu8en::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 4px solid #D5A676;
    -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }
  

#mes29 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 400;
    margin-left: 0.5rem;
}

#class9 {
    font-size: 3.2rem;
    font-weight: 600;
    color: #D5A676;
    text-transform: uppercase;
    margin-left: 1rem;
}


.carouselContainer { 
        margin-left: 11rem;
        height: 31rem;   
        gap: 1rem;
        z-index: 2;
        flex-wrap: wrap;
        display: flex;
    }



    .carouselItem {
        height: 31rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem; 
    }
 
 


#unl23 {
    width: 31%;
    height: 100%; 
    z-index: 999 !important;
    display: flex;
    cursor: pointer;
    position: relative;
    scale: 1;
    transition: transform 0.3s;
    border-radius: 1rem;
}

#unl23:hover {
    
    transform: scale(1.05);
    box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
}
#unl24 {
    width: 31%;
    height: 100%; 
    z-index: 999 !important;
    display: flex;
    cursor: pointer;
    position: relative;
    scale: 1;
    transition: transform 0.3s;
    border-radius: 1rem;
}

#unl24:hover {
    
    transform: scale(1.05);
    box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
}

#unl25 {
    width: 31%;
    height: 100%; 
    z-index: 999 !important;
    display: flex;
    cursor: pointer;
    position: relative;
    scale: 1;
    transition: transform 0.3s;
    border-radius: 1rem;
}

#unl25:hover {
    
    transform: scale(1.05);
    box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
}

#un24 {
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
}

@media only screen and (max-width: 1000px) { 

    
.g14129 {
    display: block;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0rem;
}

.tre5 {
    min-width: inherit;
    align-items: inherit;
    text-align: center;
    position: relative;
    top: 0;
    margin-bottom: 1rem;
    z-index: 1;
}

#mes29 {
    margin-left: 0;
}

#class9 {
    margin-left: 0;
}

.bsalt {
    margin-top: 0;
    font-size: 1rem;
}


.carouselContainer {
    border-radius: 0;
        height: inherit;
        margin-left: 0;
        padding-bottom: 40px;
        background: rgb(56, 51, 53);
    background: radial-gradient(circle, rgb(56, 51, 53) 0%, rgb(0, 0, 0) 100%);
}
.carouselItem {
    padding-left: 0;
    padding-right: 0;
}

#unl23, #unl24, #unl25 {
    width: 100%;
    border-radius: 0;
}


.yt01c {
    flex-basis: 10%;
    height: 3px;
    margin: 1.1rem 0;
} 

.gcdp2 {
    height: 1px;
    transition: opacity 0.3s ease-in-out 0s;
    background: #a7a6a6;
    opacity: 0.4;
}

.gcdp2u {
    height: 3px;
    transition: opacity 0.3s ease-in-out 0s;
    background: #D5A676;
    opacity: 0.9;
} 
}
@media only screen and (max-width: 600px) { 
    .yt01c {
        flex-basis: 100%;

    }
    .glu8en {
        font-size: 1.6rem;
    }

    .lg4Le {
        padding: 60px 24px 20px 24px;
    }
    #mes29 {
        font-size: 1.7rem;
    }
 
    #class9 {
        font-size: 2.6rem;
    }
    .carouselItem {
        height: inherit;
    }
    .carouselContainer {  
        
#unl23, #unl24, #unl25 {
    border: 0;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: inline-block;
    outline: 0;
    vertical-align: top;
}
       #ndlUe {
            inset: 0px;
            box-sizing: border-box;
            padding: 0px;
            border: none; 
            display: block;
            min-width: 100%;
            max-width: 100%; 
            height: 100%;
            min-height: 55vh;
            object-fit: fill;
            margin: 0;
        }
    }
}