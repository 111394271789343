.rate {
    float: left;
    height: 46px;
    padding: 0;
    position: relative;
    top: -8px;
    padding-top: 0.5rem;
}
.rate:not(:checked) > input {
    display: none;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}


.formcjs {
    padding: 0;
    width: 100%;
    padding-top: 2.5rem;
}
.input-container {
    position: relative;
    margin: 2rem 0;
  }
  
  .input {
    width: 100%;
    outline: none;
    border: 1px solid #D5A676;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
     
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
     
  }
  
  .input-container label {
    position: absolute;
    top: -25px;
    left: 0px;
    padding: 0 0.4rem;
    color: #D5A676;
    font-size: 1rem;
    font-weight: 500;
    pointer-events: none; 
    transition: 0.5s;
     
  } 
  
  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 1rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none; 
     
  }
  
  .input-container span:before,
  .input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #D5A676;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container span:before {
    left: 50%;
  }
  
  .input-container span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 1rem;
     
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
    width: 50%;
    opacity: 1;
  }

  #textarea {
    margin: 0em 0 1em 0;
  }