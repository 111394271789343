.search_header_Test {
    width: 100%;
    height: 3.9rem;
    display: flex;
  }
.eetTi { 
    width: 100%;
    height: 3.9rem;
    text-decoration: none;

    &:hover {
        background: #f4f4f4;
        border-radius: 10px;
    }
}


.search_header_Picture{
    object-fit: cover;
    width: 70px;
    height: 100%;
    vertical-align: middle;
    float: left;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    img {
        display: block;
        height: 100%;
        width: 100%;
        margin: auto;
    }
}

.search_header_Text { 
    justify-content: space-between;
    padding-right: 1rem;
    height: 100%; 
    color: #333; 
    display: flex; 
    align-items: center;
    #search_text_Price {
        font-size: 0.9rem;
    }
    #search_text_Brand {
        font-size: 0.9rem;
        text-decoration: none;
    }
}



@media only screen and (max-width: 600px) { 
    .search_header_Picture{
        object-fit: cover;
        width: 50px;
        height: 100%;
        float: left;
        margin-right: 0;
    
        img {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

    .search_header_Text {  
        padding-right: 0.5rem; 
    
        #search_text_Price {
            font-size: 0.8rem;
        }

        #search_text_Brand {
            font-size: 0.8rem; 
        }
    }

}