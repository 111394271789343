.highlights {
    width: 100%; 
    background: rgb(56,51,53);
background: radial-gradient(circle, rgba(56,51,53,1) 0%, rgba(0,0,0,1) 100%);
    padding-bottom: 4rem;
}
 
.glu8en { 
      font-size: 2rem;
      color: #fff; 
      position: relative;
  }
  
  .glu8en::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 4px solid #D5A676;
    -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }
  
.lock7 {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 24px 30px 24px;
}
 




.styledArrowRight {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    right: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 100%;
    width: 32px;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    z-index: 4!important;
    color: #fff;
}

.styledArrowLeft {
    z-index: 4!important;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    left: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 100%;
    width: 32px;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    color: #fff;
} 
 

.carouselContainer {
    max-width: 1216px;
    margin: 0 auto;
    display: flex;
}
.yt01c {
    flex-basis: 20%;
    height: 3px;
    margin: 1.1rem 0;
} 

.gcdp2 {
    height: 1px;
    transition: opacity 0.3s ease-in-out 0s;
    background: #a7a6a6;
    opacity: 0.4;
}

.gcdp2u {
    height: 3px;
    transition: opacity 0.3s ease-in-out 0s;
    background: #D5A676;
    opacity: 0.9;
} 

.carouselItem {
    display: inline-block;
    flex: inherit;
}
.py3sl0 {
    width: 294px;
    margin: 0 auto;
    padding-top: 2rem;
}

.xlde {
    height: 24rem;
    margin-bottom: 1rem;
    position: relative;
    align-items: center;
    justify-content: center;
}


#hlp {
    max-height: 24rem;
    height: 24rem; 
    background: url('../../../../images//imagez/pur2tt.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#hlp:hover, #hlp2:hover, #hlp3:hover, #hlp4:hover {
    transform: scale(1.05);
}


#hlp2 {
    max-height: 24rem;
    height: 24rem; 
    background: url('../../../../images//imagez/redlolit.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} 

#hlp3 {
    max-height: 24rem;
    height: 24rem; 
    background: url('../../../../images/imagez/sweetpleasure.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
 



#hlp4 {
    max-height: 24rem;
    height: 24rem; 
    background: url('../../../../images/lightbluet.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
 


.css14 {
    font-size: 1em;
    font-weight: 700;
    color: #D5A676;
    text-align: center;
    margin-top: 2rem;
}

.erb29 {
    text-align: center;
    text-transform: uppercase; 
    font-size: 1rem;
    line-height: 1.9rem;
    color: #D5A676;
    font-weight: 200;
    letter-spacing: 0.1em; 
    text-decoration: none;
}

.ght25 {
    color: #fff;
    display: block;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.925rem;
    letter-spacing: 0.15em; 
}


.ider0 {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

#price {
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.75px;
    font-size: 1rem; 
    line-height: 24px;
}

#arrowright {
    transform: rotate(270deg);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35%;
    font-size: 2rem;
    margin-right: 1rem;
}

#arrowleft {
    transform: rotate(90deg);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35%;
    font-size: 2rem;
    margin-left: 1rem;
}
@media only screen and (max-width: 1200px) { 
.carouselContainer {
    padding-bottom: 2rem;
   }

}
@media only screen and (max-width: 600px) { 
   #hlp, #hlp:hover,#hlp2,#hlp2:hover,#hlp3,#hlp3:hover,#hlp4,#hlp4:hover  {
    background-position: 50%;
    height: 20rem;
   }

   #hlp:hover {
    background-image: url('../../../../images/mirabelblack.png');
   }
   #hlp2:hover {
    background-image: url('../../../../images/mirabelred.png');
   }
   #hlp3:hover {
    background-image: url('../../../../images/mirabelpink.png');
   }
   #hlp4:hover {
    background-image: url('../../../../images/mirabelpurple.png');
   }
 
   #arrowleft {
    margin-left: 0;
   }
   
   #arrowright {
    margin-right: 0;
   }
   .py3sl0 {
    width: auto;
   }

   .css14 {
    margin-top: 1rem;
   }

   .ght25 { 
    margin-top: 0.5rem;
   } 
    }
