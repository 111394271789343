.d30pNi {  
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 40px; 
    border: none;
    z-index: 1; 
  }
  

.d30pNileft {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 40px; 
    border: none;
    z-index: 1; 
}

.d30pNileftnone, .d30pNinone { 
    visibility: hidden;
}
.d30pNileft:hover {
    color: unset;
    background: unset;
}

.d30pNi:hover {
    color: unset;
    background: unset;
}

.carouselItem { 
 width: auto;
    ul {
        li { 
        }
    }
}

.ht0agk {
    list-style: none;
    margin-right: 0px;
    height: 100%;
    padding: 0 16px;

    a {
        height: 100%;
        color: rgb(33, 33, 33);
        font-weight: 400;
        padding: 10.5px 0;
    }

   
}

.carouselContent {
    display: flex;
}

 

.raRaj8 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 40px;
    height: 100%;
    background: linear-gradient(270deg, rgb(255, 255, 255) 48.44%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(0deg);
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    right: 0px;
}

.raRaj9 {
    display: none;
}
 

.raLej8 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 40px;
    height: 100%;
    background: linear-gradient(270deg, rgb(255, 255, 255) 48.44%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(180deg);
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 1;
    left: 0px;
}

.raLej9 {
    display: none;
}