.navbar {
    justify-content: space-between;
    display: flex;
}

.nt3350 {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 20px 30px;
    position: relative;
    width: 100%;
}

.fil12 {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.cartPicture {
    align-items: center;
    display: flex;
    height: 66px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    width: 66px;
    border-radius: 6px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#cartPhoto {
    height: auto;
    max-height: 66px;
    width: 100%;
}

.xcEcl {
    flex: 1 1 auto;
    max-width: 100%;
    padding-left: 1rem;
}

.vnxN {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: relative;
}


.grad {
    align-items: center;
    border-radius: 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0 5px;
}

#trashcan { 
    height: 15px !important;
    min-width: 15px !important;
    fill: #717171;
}


.grad:hover #trashcan {
    fill: red;
}

#prodTitle {
    font-size: 1.1rem; 
}

.prodColor {
    font-size: 14px;
    color: #717171;
    font-weight: 400;
}

.rowP {
    
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.d8e2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.pCount {
    align-items: center;
    display: flex;
}

.minus {
    border-radius: 6px;
    margin-right: 4px;
    align-items: center;
    background-color: #f6f6f6;
    fill: #000000;
    color: #000;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    max-height: 30px;
    min-height: 30px;
    padding: 0;
    text-align: center;
    transition: background-color .2s;
    width: 30px; 
}


#productMinus {
    height: 12px !important;
    width: 12px !important; 
}

.currentProduct {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border-radius: 6px;
    border: none;
    -webkit-text-fill-color: #000;
    -webkit-appearance: none; 
    border-left: none; 
    border-right: none;
    color: #000;
    font-size: 14px;
    height: 30px;
    margin: 0;
    max-height: 30px;
    min-height: 30px;
    opacity: 1;
    padding: 0;
    text-align: center;
    width: 45px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.plus {
    border-radius: 6px;
    margin-left: 4px;
    border: none;
    background-color: #f6f6f6;
    fill: black;
    align-items: center; 
    color: black;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    max-height: 30px;
    min-height: 30px;
    padding: 0;
    text-align: center;
    transition: background-color .2s;
    width: 30px; 
}

.plus:hover {
    background-color: #EBEBEB;
}

.minus:hover {
    background-color: #EBEBEB;
}

#productPlus {
    height: 12px !important;
    width: 12px !important;
    fill: #638381;
}


.productPrice {
    display: flex;
    flex-direction: column;
    gap: 2px;
    line-height: 1.2;
    text-align: right;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


@media screen and (max-width: 600px) { 
.nt3350 {
    padding: 20px 5px;
}

.xcEcl {
    padding-left: 0.5rem;

}

#prodTitle {
    font-size: 1rem;
}
}

@media only screen and (max-width: 450px) {  

    .minus {
        border-radius: 6px;
        margin-right: 4px;
        align-items: center;
        background-color: #f6f6f6;
        fill: #000000;
        color: #000;
        cursor: pointer;
        display: flex;
        height: 25px;
        justify-content: center;
        max-height: 25px;
        min-height: 25px;
        padding: 0;
        text-align: center;
        transition: background-color .2s;
        width: 25px; 
    }

    #prodTitle {
        font-size: 0.9rem;
    }

    .prodColor {
        font-size: 12px;
        color: #717171;
        font-weight: 400;
    }

    .cartPicture {
        align-items: center;
        display: flex;
        height: 60px;
        justify-content: center;
        overflow: hidden;
        text-align: center;
        width: 60px;
        border-radius: 6px;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    #cartPhoto {
        height: auto;
        max-height: 60px;
        width: 100%;
    }

    .currentProduct {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f6f6f6;
        border-radius: 6px;
        border: none;
        -webkit-text-fill-color: #000;
        -webkit-appearance: none; 
        border-left: none; 
        border-right: none;
        color: #000;
        font-size: 12px;
        height: 25px;
        margin: 0;
        max-height: 25px;
        min-height: 25px;
        opacity: 1;
        padding: 0;
        text-align: center;
        width: 45px;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .plus {
        border-radius: 6px;
        margin-left: 4px;
        border: none;
        background-color: #f6f6f6;
        fill: black;
        align-items: center; 
        color: black;
        cursor: pointer;
        display: flex;
        height: 25px;
        justify-content: center;
        max-height: 25px;
        min-height: 25px;
        padding: 0;
        text-align: center;
        transition: background-color .2s;
        width: 25px; 
    }

    .productPrice {
        display: flex;
        flex-direction: column;
        gap: 2px;
        line-height: 1.2;
        text-align: right;
        font-size: 0.9rem;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    

 }