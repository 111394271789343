 
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem 2rem;
}

.product {
    width: 200px;
    height: 100px;
}


@media only screen and (max-width:800px) {

    .grid {
        grid-template-columns: 1fr 1fr;
    }

}


@media only screen and (max-width:530px) {
    .grid {
        grid-template-columns: 1fr;
    }

}