.bbForm {
  position: absolute;
  right: 0;
    display: flex;
    line-height: 1em;
    margin: 0; 
    width: 200px;
    border: 1px solid rgba(224,224,224, 0.8);
    background-color: rgba(246,248,249, 1);
    align-items: center;
    border-radius: 8px 24px 24px 8px;
}



.bbForm:focus-within {
    border-color: rgba(255,69,0, 1);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
-moz-box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
box-shadow: 0px 0px 3px 0px rgba(255,68,0,0.2);
    outline: medium none currentColor;
}

.entCo {
    object-fit: cover;
    width: 25px;
    height: 100%;
    vertical-align: middle;
    float: left;
    margin-right: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

.lateIso {
    object-fit: cover;
    float: left;
    height: 100%;
    text-align: left;
    color: black; 

    #latePi {
        vertical-align: middle;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.75px;
    }
    }
 

 
  .seRrat {
    width: 100%;
    display: block;
  }


  .rf1D0 {
    width: 100%;
  }

  .catIf { 
    display: inline-block;
    margin: 5px;
    height: 32px;
    padding: .25rem .5rem;
    border: 1px solid rgba(224, 224, 224, 0.8);
    background-color: rgb(246, 248, 249);
    align-items: center;
    border-radius: 8px;
}
 

.suggestedSearch {
    align-items: center;
    color: #737373;
    display: flex;
    font-size: .7rem;
    line-height: 1;
    white-space: nowrap;
    padding: 10px;
  }

  .suggestedSearch:after {
    background: #d1d1d1;
    content: "";
    display: block;
    flex-grow: 1;
    height: 1px;
    margin-left: 1.5em;
  }
  
  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .showC {
  display: block;
  }

.hideZ {
  display: none;
}

  .search_header_Categories {
    position: absolute;
    right: 0;
    width: 300px;
    top: 40px;
    background-color: white;
    margin: 8px 0 0;  
    -webkit-box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
-moz-box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
border-radius: 10px;
}

.search_header_CategoriesHide {
  display: none;
  position: absolute;
  right: 0;
  width: 300px;
  top: 40px;
  background-color: white;
  margin: 8px 0 0;  
  -webkit-box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
-moz-box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
box-shadow: 0px 0px 2px 0px rgba(84,83,84,0.5);
border-radius: 10px;
}
.searchInput {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    color: #262627; 
    font: inherit;
    height: 44px;
    height: 44px;
    padding: 16px;
    width: 100%;
  }
  
  
  .searchInput:focus {
    border-color: none;
    box-shadow: none;
    outline: none;
  }
  
  @media only screen and (max-width: 1000px) { 
    .hideZ {
      display: inherit;
      width: 100%;
    };
    .search_header_Categories {
      position: absolute;
    right: 0;
    width: 100%;
    top: 40px;
    background-color: white;
    margin: 8px 0 0;
    box-shadow: 0px 0px 2px 0px rgba(84, 83, 84, 0.5);
    border-radius: 10px;
    z-index: 4;
    }
    }
  
@media only screen and (max-width: 700px) { 
    .search_header_Categories {
        max-height: 300px;
      }
    }
      
    
    @media only screen and (max-width: 400px) { 
        
        .search_header_Categories {
            max-height: 255px;
          }
        }    
   