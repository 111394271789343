body {
    
  
  font-optical-sizing: auto; 
  font-style: normal; 
}


.Container {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
};