.button {
    border: 1px solid #000;
    background: transparent;
    margin: 5px 5px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .false {
    
    input[type="checkbox"]::before {
      
     opacity: 0; 
      content: '';
      display: block;
      top: -2px;
      left: 3px;
      width: 8px;
      height: 14px; 
    }
  }

div .active {
    input[type="checkbox"] {
      border-color: #240090;
    box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-webkit-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-moz-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);

    }

    input[type="checkbox"]::before {
      position: absolute;
      content: '\2714';
      display: block;
      top: -2px;
      left: 3px;
      width: 8px;
      height: 14px;  
      opacity: 1;
      color: #240090;
    }
       
}

input[type="checkbox"]:hover {
    border-color: #240090;
    box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-webkit-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-moz-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
}
input[type="checkbox"] { 
  top: 2px;
  margin-right: 5px;
  display: inline-block;
  float: left;
    position: relative;
    width: 1.25em;
    height: 1.25em; 
    border: 1px solid #1b1e243d;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  }
    input[type="checkbox"]::before {
      position: absolute;
      content: '\2714';
      display: block;
      top: -2px;
      left: 3px;
      width: 8px;
      height: 14px;  
      opacity: 0;
      color: #240090;
    }
    input[type="checkbox"]:checked {
      color: white;
      border-color: #240090;
      box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-webkit-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
-moz-box-shadow: 0px 0px 2px 1px rgba(36,0,144,0.2);
      background: white;

    }
      input[type="checkbox"]:checked:before {
        opacity: 1;
      }
      label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
  
  label {
    position: relative;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600; 
    user-select: none;
  }
    

 
.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
}

 

.filter-condition{ 
  font-size: 1rem;
  font-weight: bold;
}
.filter-condition select{ 
    border: none;
    outline: none; 
    color: #240090;
    background: transparent;
    cursor: pointer;
    position: relative;
    left: -5px; 
    width: 135px;
}

.filter-condition select option{
 font-size: 0.9rem; 
}

.filter-condition select:hover {
  font-weight: bold;
}
 

div .yes  {

  position: fixed;
  overflow: hidden;
  width: 100%;
  top: 131px;
  vertical-align: top; 
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  background-color: white;
  z-index: 1042;
  visibility: visible;
  position: visible;
  left: 0; 
  display: block;
}

.FiltersOpenedWrapper {
  position: unset;
overflow: hidden;
width: 100%;
top: 131px;
vertical-align: top; 
align-items: center;
height: 100vh;
margin: 0 auto;
background-color: white;
z-index: 1042;
visibility: visible;
position: hidden;
left: 0; 
display: none;
}
.ProductsWrapper {
  width: 100%;
  height: 132px; 
  top: 0;
  z-index: 1042;
  position: unset;
  visibility: visible;
  height: unset;
width: unset;
}

div .true{
  width: 100%;
  height: 132px; 
  top: 0;
  z-index: 1042;
  position: fixed;
  visibility: visible;
  height: 100vh;
width: 100vw;
}

@media screen and (min-width: 750px) {
 
 div .yes {
    position: unset;
  overflow: hidden;
  width: 100%;
  top: 131px;
  vertical-align: top; 
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  background-color: white;
  z-index: 1042;
  visibility: visible;
  position: hidden;
  left: 0; 
  display: none;
  }
  

}