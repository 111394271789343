.productWr {
    display: grid;
    grid-template-columns: 1fr;
}

.incart {
    display: flex;
}

.productImage {  
    position: relative;
}

#productImage {
    object-fit: contain;
    width: 100%;
    height: 80%;
    max-height: 30rem; 
}

.productDetails {
    justify-content: center;
    display: grid;
    text-align: center; 
}

.productTitle {
    font-size: 1.1rem;
    margin-top: 0.4rem;
    font-weight: 400; 
    color: #D5A676;
}

#productPriceDiscount {
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
    margin: 0.5rem 0;
}
 

#details {
    position: relative;
    top: 3px;
}


.productButtonCart, .productButtonDetails {
    color: #fff;
    text-decoration: none;
    border: 1px solid;
    border-radius: 6px;
    padding: 0.4rem;
}

.productButtonCartAdded {
    color: #fff;
    text-decoration: none;
    border: 1px solid;
    border-radius: 6px;
    padding: 0.4rem;
    background-color: #D5A676;
}

.productButtonCart:hover, .productButtonDetails:hover {
    background-color: #D5A676;
    transition: 0.3s;
}

#detailscart {
    position: relative;
    top: 3px;
    margin-left: 3px;
}

.priceWrapper {
    margin-top: 0.3rem;
}

.buttonsDetailsBuy {
    margin-top: 0.3rem;
}

.productSize {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    min-height: 2rem;
    align-items: center;
    background: rgb(246, 248, 249);
    transform: translateY(1rem);
    visibility: hidden;
    border-top: 1px solid #D5A676;
}

#sizeSpacing {
    letter-spacing: 0.2rem;
}

.productCard {
    cursor: pointer;
    max-width: 255px;
    margin: 0 auto;
}

.productCard:hover .productSize {
    transform: translateY(0);
    visibility: visible;
    transition: all .6s;
}

.productCard:hover #productImage {
    transform: scale(1.02);
    transition: all 0.3s;
}

.productCard:hover .productImage {
    border-bottom: none;
}

.buttonsBottomBuy {
    display: flex;
    gap: 1rem;
    margin: 0 auto;
    padding-top: 0.4rem;
     
}


@media only screen and (max-width:800px) {
   .productCard {
    max-width: 280px;
   }

   #productTitle {
    font-size: 1rem;
   }

   #productPriceDiscount {
     margin-bottom: 0.3rem;
    font-size: 1rem;
   }

   .productSize {
   visibility: hidden;
}

.productCard:hover .productSize {
    transform: translateY(1rem);
    visibility: hidden;
    transition: all .6s;
}

.productCard:hover #productImage {
    transform: none;
    transition: all 0.3s;
}

.productCard:hover .productImage {
    
}

}