

select.classic {
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #D5A676, #D5A676);
    background-position: calc(100% - 19px) calc(1em + -5px), calc(100% - 15px) calc(1em + -5px), 100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    margin-right: 10px;
    cursor: pointer;
  }
  
  select.classic:focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, gray, gray);
    background-position:
      calc(100% - 15px) 0.6em,
      calc(100% - 19px) 0.6em,
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }
  select {

    margin-top: 1rem;
    /* styling */
    background-color: white;
    border: thin solid #D5A676;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0em 3.5em 0em 1em;
  
    /* reset */
       
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  