.scrollable-tabs-container {
  font-family: "Roboto", sans-serif;
  background: #000;
  max-width: 700px;
  margin: 16px auto;
  border-radius: 4px;
  overflow: hidden;
  position: relative; 
}

.scrollable-tabs-container svg {
  width: 24px;
  height: 24px;
  padding: 8px;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  pointer-events: auto;
}

.scrollable-tabs-container ul {
  display: flex;
  gap: 16px;
  padding: 12px 24px;
  margin: 0;
  list-style: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.scrollable-tabs-container ul.dragging a {
  pointer-events: none;
}

.scrollable-tabs-container ul.dragging {
  scroll-behavior: auto;
}

.scrollable-tabs-container ul::-webkit-scrollbar {
  display: none;
}

.scrollable-tabs-container a {
  color: #fff;
  text-decoration: none;
  background: #333;
  padding: 4px 24px;
  display: inline-block;
  border-radius: 4px;
  user-select: none;
  white-space: nowrap;
}

.scrollable-tabs-container a.active {
  background: #fff;
  color: #000;
}

.scrollable-tabs-container .right-arrow,
.scrollable-tabs-container .left-arrow {
  position: absolute;
  height: 100%;
  width: 100px;
  top: 0; 
  align-items: center;
  padding: 0 10px;
  pointer-events: none;
}

.scrollable-tabs-container .right-arrow.active,
.scrollable-tabs-container .left-arrow.active {
  display: flex;
}

.scrollable-tabs-container .right-arrow {
  right: 0;
  background: linear-gradient(to left, #000 50%, transparent);
  justify-content: flex-end;
}

.scrollable-tabs-container .left-arrow {
  background: linear-gradient(to right, #000 50%, transparent);
}

.scrollable-tabs-container svg:hover {
  background: #333;
}



.items {
  display: flex;
  width: 404px;
  overflow-y: scroll;
}