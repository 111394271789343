.penman {
    background: rgb(56,51,53);
background: radial-gradient(circle, rgba(56,51,53,1) 0%, rgba(0,0,0,1) 100%);
    width: 100%;
    background-image: url('../../../../images/pleasurebcg.png'); 
    position: relative;
  }
  
.selHD {
    
    display: grid;
    grid-template-columns: 1fr 1.4fr 0.75fr;
    max-height: 600px;
    max-width: 1216px;
    margin: 0 auto;
}


.dth89 {
    border-radius: 1rem;
    min-width: 195px;
    position: relative;  
    background: transparent;
    transition: all .3s;
    padding: 0.7rem 0;
    justify-content: center;
    text-align: left;
    display: inline-block; 
    color: white;
    font-size: 1.3rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 400;   
    text-decoration: none;
    margin-top: 2rem; 
}
.dth89:hover {
    color: #D5A676;
}
.dth89:hover #arrowright {
    transform: translatex(1REM) ROTATE(315DEG);
}

.dth23 {
    display: none;
}


#arrowright {
    position: absolute;
    top: 10px;
    margin-left: 0.7rem;
    transform: rotate(315deg) translateX(0);
    -webkit-transform: rotate(315deg);
    font-size: 1.6rem;
    transition: transform 0.3s; 
}


.momDe { 
    position: relative;
    max-height: 600px;

    img {
        width: 100%;
        height: 100%;
    }
}

.divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 1px solid #D5A676;
    width: 100px;

}

.emoJ {
    padding: 2rem 0rem 2rem 1rem;
    margin-right: 2rem;
}

#bec2 {
    color: #D5A676;
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}


#bec3 {
    font-size: 4rem;
    color: white;
    font-weight: 300;

    i {
        color: #D5A676;
        font-weight: 500;
        font-size: 4rem;
    }
}

#bec4 {
    color: white;
    margin-bottom: 4rem;
    font-style: italic;
}


.py3sl0 {
    padding-top: 2rem;
}

.xlde {
    position: relative;
    align-items: center;
    justify-content: center;
    height: 16rem;
    margin-bottom: 0rem;
    display: flex;
} 

#hlp {
    max-height: 16rem;
    height: 28rem;
    object-fit: cover; 
} 

.py3sl0:hover #hlp {
    transform: scale(1.05);
}
.css14 {
    font-size: 1em;
    font-weight: 700;
    color: #D5A676;
    text-align: center;
    margin-top: 2rem;
}

.erb29 {
    text-align: center;
    text-transform: uppercase; 
    font-size: 1rem;
    line-height: 1.9rem;
    color: #D5A676;
    font-weight: 200;
    letter-spacing: 0.1em; 
    text-decoration: none;
}

.ght25 {
    color: white;
    display: block;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.925rem;
    letter-spacing: 0.15em; 
}


.ider0 {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

#price {
    color: white;
    font-weight: 300;
    letter-spacing: 0.75px;
    font-size: 1rem; 
    line-height: 24px;
}

.oaH76 {
    display: flex;
    justify-content: center;
    gap: 2rem; 
}


#divider {
    width: 1px;
    border-right: 1px solid;
    color: #D5A676;
}

.ndle {
    padding: 1rem 1rem 1rem 4rem;
}

.spoTm {
    display: flex;
    color: white;
    gap: 1rem;
    padding-top: 3rem;
    justify-content: center;
}

#one2 {
    display: flex;
    font-size: 1rem;
    justify-content: center; 
    align-items: center;
}

#one141 {
    font-size: 0.8rem;
    font-weight: 400;
    align-items: center; 
    display: flex;
}


.deo4 {
    display: none;
}


@media only screen and (max-width: 1200px) {  
 .emoJ {
    padding: 2rem 1rem 2rem 1rem;
    margin-right: 0;
    grid-area: 1 / 1 / auto / 4;
    text-align: center;
 }

 .ndle {
    padding: 1rem 1rem 1rem 1rem;
    margin: 0 auto;
    grid-area: 2 / 1 / auto / 4;
}

.selHD {
    max-height: inherit;
    grid-template-columns: 1fr;
}


.deo4 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.momDe {
    display: none;
} 


#bec2 { 
font-size: 1rem;
margin-bottom: 1rem;
}

#bec3 {
    font-size: 2rem;
color: white;
font-weight: 300;
i {
    font-size: 2rem;
}
}

.divider {
    border-top: 1px solid #D5A676;
width: 50%;
margin: 1rem auto 1rem auto;
}

.dth89 {
    display: none;
}


.dth23 {
border-radius: 1rem;
min-width: 160px;
position: relative;  
background: transparent;
transition: all .3s;
padding: 0.7rem 0;
justify-content: center;
text-align: left;
display: inline-block; 
color: white;
font-size: 1.3rem;
letter-spacing: 2px;
text-transform: uppercase;
font-weight: 400;   
text-decoration: none; 
}
.dth23:hover {
color: #D5A676;
}
.dth23:hover #arrowright {
transform: translatex(1REM) ROTATE(315DEG);
}
 
.oaH76 {
display: flex;
justify-content: space-around;
gap: 2rem;
}

.spoTm {
max-width: 26.25rem;
padding-top: 2rem;
}
 

#bec4 {
margin-bottom: 0;
}
 }


 @media only screen and (max-width: 700px) {  

    .momDe {
        display: inherit;
        max-height: inherit;
    }

    .ndle {
        grid-area: 3 / 1 / auto / 4;
    }


 }


 @media only screen and (max-width: 450px) {  
    .oaH76 {
        display: grid;
        justify-content: inherit;
    }
 }
 
 