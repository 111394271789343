@import "global/styles";

html {
  box-sizing: border-box; 
  -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  font-family: $font-family;
}

