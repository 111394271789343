.footerParent {
    width: 100%;
    margin: 0 auto;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-top-color: #D5A676;   
    background: rgb(56, 51, 53);
    background: radial-gradient(circle, rgb(56, 51, 53) 0%, rgb(0, 0, 0) 100%);
     }
  
     .var975y4 {
      width: 100%;
      margin: 0 auto; 
      background-color: #191919;
      padding-left: 4em;
      padding-right: 4em;
      padding-top: 10px;
      padding-bottom: 10px;
     }
     .nin6500 {
      grid-gap: 5px;
      display: grid;
      max-width: 1216px; 
      margin: 0 auto; 
      grid-template-columns: 2fr 1fr;  
      min-height: 40px;
      align-items: center;
     }
  
     .uts4924 {
  
      span {
        p {
          margin: 0;
          font-size: 0.8em;
          display: inline-block;
          color: #9ca7ab;
        }
      }
      #r7et82 {
        text-decoration: none;
        cursor: pointer;
        color: #9ca7ab;
        font-size: 0.8em;
      }
     }
  
     .a11yjs {
      text-align: right;
      display: flex;
      justify-content: right;
      text-align: right;
      position: relative;
     }
  
     .footer {
      
    padding-left: 4em;
    padding-right: 4em;
      position: relative;
      width: 100%;
      margin: 0 auto;
      height: auto;
     }
  
     .contain {
      width: 100%;
      margin: 0 auto;
      padding-top: 2rem;
      padding-bottom: 2rem;
      max-width: 1216px;
     }
     .footer .col {
       width: 190px;
       height: auto;
       float: left;
       box-sizing: border-box;
       -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
       padding: 0px 30px 20px 10px;
     }
     .footer .col h1 {
       margin: 0;
       padding: 0; 
       font-size: 1.1em;
       line-height: 1.1em;
       padding: 20px 0px 5px 0px;
       color: #D5A676; 
       text-transform: uppercase;
       letter-spacing: 0.15em;
       font-weight: 500;
     }
     .footer .col ul {
       list-style-type: none;
       margin: 0;
       padding: 0;
     }
     .footer .col ul li {
       color: #fff;
       font-size: 0.9rem;  
       padding: 5px 0px 5px 0px;
       cursor: pointer;
       transition: .2s;
       -webkit-transition: .2s;
       -moz-transition: .2s;
  
       a {
        color: #fff;
        scroll-behavior: smooth;  
        text-decoration: none;
       }
     }
     .colSocial ul li {
       display: inline-block;
       padding-right: 5px !important;
     }
     
     .footer .col ul li:hover {
       color: #D5A676;
       transition: .1s;
       -webkit-transition: .1s;
       -moz-transition: .1s;
     }
  
     .footer .col ul li a:hover {
      text-decoration: none;
      color: #D5A676;
      transition: .1s;
      -webkit-transition: .1s;
      -moz-transition: .1s;
    }
     .clearfix {
       clear: both;
     }
     
     #socialUl {
       li {
         font-size: 25px;
         width: 25%;
         float: left;
       }
     }
     
     #socialLi {
       font-size: 25px;
       width: 25%;
       float: left;
     }
      
     @media only screen and (max-width: 1139px) {
       
       .colSocial h1 {
         margin: 0px;
       }
     }
   
  
    @media only screen and (max-width: 1200px) {
      .footer {
        padding-left: 2em;
        padding-right: 2em;
      }
  
      .var975y4 {
        padding-left: 2em;
        padding-right: 2em;
      }
    }
  
     @media only screen and (max-width: 950px) {
       .footer .col {
         width: 33%;
         min-height: 108px;
         padding: 0px 30px 0px 10px;
       }
       .footer .col h1 {
         font-size: 1.1em;
       }
       .footer .col ul li {
         font-size: 0.9em;
       }
     }
  
  
     @media only screen and (max-width: 700px) {
      .nin6500 {
        grid-template-columns: 1fr;
        text-align: center;
       }
       .a11yjs {
        display: inherit;
        justify-content: center;
       }
     }
    
     @media only screen and (max-width: 500px) {
   
  
       .footer {
        padding-left: 1em;
        padding-right: 1em;
       }
  
       .var975y4 {
        padding-left: 1em;
        padding-right: 1em;
      }
     
     }
  
     @media only screen and (max-width: 550px) {
      .footer .col {
        width: 50%;
        min-height: 108px;
      }
      .footer .col h1 {
        font-size: 1.1em;
      }
      .footer .col ul li {
        font-size: 0.9em;
        padding: 3px 0;
      }
     }
     @media only screen and (max-width: 340px) {
       .footer .col {
         width: 100%;
         min-height: inherit;
       }
     }