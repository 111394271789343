 

.maLika {
    font-size: 0.9rem;
    text-decoration: none; 
    color: rgb(45, 41, 38);
    letter-spacing: 1px;
    margin-right: 400px;
}

.kame7 {
    color: rgb(45, 41, 38);
    font-weight: 500;

    img { 
    width: 16px; 
    height: 16px; 
    position: absolute;
    top: 50%;
    margin-left: 8px; 
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    }
}

.video {
    width: 100%;
    object-fit: cover;
    object-position: 30% 50%;
    filter:blur(2px);
} 

#pmGn1 {
    width: 100%;
    object-fit: cover;
    object-position: 44% 30%; 
    height: 720px;
}

#pmGn2 {
    width: 100%;
    object-fit: cover;
    object-position: 35% 30%; 
    height: 720px;
}

.ipt49 {
    pointer-events: none;
    color: #ffffff;
    font-size: 3.5rem;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    text-align: center;
    font-weight: 200; 
}

.ent7141 {
    text-align: center;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 65%);
    width: 95%;
    height: auto;
}

.dth89 {
    border-radius: 1rem;
    min-width: 195px;
    position: relative; 
    border: 1px solid #D5A676;
    background: transparent;
    transition: all .3s;
    padding: 0.7rem;
    justify-content: center;
    text-align: left;
    display: inline-block; 
    color: white;
    font-size: 1.3rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 400;   
    text-decoration: none;
    margin-top: 2rem; 
}
.dth89:hover {
    color: #D5A676;
}
.dth89:hover #arrowright {
    transform: translatex(1REM) ROTATE(315DEG);
}

#arrowright {
    position: absolute;
    top: 10px;
    margin-left: 0.7rem;
    transform: rotate(315deg) translateX(0);
    -webkit-transform: rotate(315deg);
    font-size: 1.6rem;
    transition: transform 0.3s; 
}

.carouselContainer {
    background: rgb(56, 51, 53);
    background: radial-gradient(circle, rgb(56, 51, 53) 0%, rgb(0, 0, 0) 100%);
}


.styledArrowRight {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    right: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 100%;
    width: 20px;
    border-radius: 8px 0px 0px 8px;
    display: flex;
    z-index: 4!important;
}

.styledArrowLeft {
    z-index: 4!important;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    left: 0;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 100%;
    width: 20px;
    border-radius: 8px 0px 0px 8px;
    display: flex;
} 

.yt01c {
    flex-basis: 44px;
    height: 6px;
    margin: 1.1rem 0.5rem;
} 

.gcdp2 {
    height: 6px;
    border-radius: 30px;
    transition: opacity 0.3s ease-in-out 0s;
    background: rgb(255, 255, 255);
    opacity: 0.4;
}

.gcdp2u {
    height: 6px;
    border-radius: 30px;
    transition: opacity 0.3s ease-in-out 0s;
    background: #D5A676;
    opacity: 0.9;
} 

.dotom {
    display: flex;
}

.ent7141h {
    text-align: center;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translate(-30%, 100%);
    width: 95%;
    height: auto;
}

.gpUDA {
    display: grid;
    text-align: left;
    justify-content: center;
    gap: 0.8rem;
} 

.dNZ9p {
    display: flex;
    gap: 1rem;
}
 

#ph7z {
    font-size: 3.2rem;
    color: #D5A676;
}

#Yoj1 {
    font-size: 1.6rem;
    color: white;
}

#Yoj12 {
    font-size: 3.2rem;
    color: white;
}

#tnUIq {
    pointer-events: none;
    font-size: 4.2rem;
    color: #D5A676;
}


@media only screen and (max-width: 1000px) {
  
    .video {
        object-position: 50% 50%;  
    }

    .ipt49 {
        font-size: 2.4rem;
    }

    .ent7141 {
        transform: translate(-50%, 80%);
    } 
    .dth89 {
        min-width: 165px; 
        padding: 0.6rem; 
        font-size: 1rem; 
    }

    #arrowright {
        top: 9px;
        font-size: 1.3rem;
    }
    }

    @media only screen and (max-width: 700px) { 
        .ent7141 {
            transform: translate(-50%, 75%);
        } 
    }
@media only screen and (max-width: 600px) { 
    
    .gpUDA {
        gap: 0;
    }
        .ipt49 {
            font-size: 6vw;
        }

        .ent7141 {
            transform: translate(-50%, 70%);
        }

        #pmGn1 {
            object-fit: fill;
            object-position: 0%;
        }
        #pmGn2 {
            object-position: 42% 30%;
        }
        .ent7141h {
            transform: translate(-30%, 100%);
        }

        #Yoj1 {
            font-size: 1.2rem;
        }

        #ph7z {
            font-size: 2.6rem;
        }

        #Yoj12 {
            font-size: 2.6rem;
        }

        #tnUIq {
            font-size: 3.4rem;
        }

}


@media only screen and (max-width: 500px) {  
    .ent7141h {
        transform: translate(-50%, 100%);
    } 
    .ent7141 {
        transform: translate(-50%, 120%);
    }
    #ph7z {
        font-size: 2.1rem;
    }

    #Yoj12 {
        font-size: 2.1rem;
    }

    #tnUIq {
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 400px) {  
    .ent7141h {
        transform: translate(-50%, 100%);
    }  
}
// .dth89::before {
//     border-color: #c16b00;
//     border-style: solid;
//     border-width: 0 0 2px 2px;
//     content: "";
//     display: block;
//     height: 11px;
//     right: -50px;
//     position: absolute;
//     width: 11px;
//     margin-top: -4px;
//     top: 50%;
//     transition: left 0.1s;
//     transform: rotate(45deg);
//     -webkit-transform: rotate(225deg);
// }

// .dth89::after {
//     background: #c16b00;
//     content: "";
//     display: block;
//     height: 3px;
//     right: -50px;
//     position: absolute;
//     top: 50%;
//     width: 36px;
//     transition: left 0.1s;
//   }