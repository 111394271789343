 
  .search-toggle {
    position: absolute;
    top: 50%;
    right: 0;  
  }
  
  /* the animated search icon */
  .search-toggle {
    color: currentColor;
    background: rgba(255,255,255,.3);
    box-shadow: 0 0 1em 0 rgba(0,0,0,.1);
    border-radius: 100%;
    height: 2em;
    width: 2em;
    font-size: 1em;
    line-height: 0;
    transform: translate(-50%, -50%);
  }
  
  .searchIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-size: 60%;
    color: currentColor;
    background: rgba(255,255,255,.3);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: all .5s ease-in-out;
    line-height: 0;
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      border-radius: 2em;
      transition: all .5s ease-in-out;
    }
  
    &:before {
      box-shadow: 0 0  0 .25em;
      width: 1em;
      height: 1em;
      top: 50%;
      left: 50%;
      transform: translate(-75%, -75%);
    }
    &:after {
      width: 1em;
      height: .3em;
      background: currentColor;
      transform: translate(0%, 100%) rotate(45deg);
      top: 50%;
      left: 50%;
    }
  }


  .searchIconOpen {
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    font-size: 60%;
    color: currentColor;
    background: rgba(255, 255, 255, .3);
    border-radius: 100%; 
    transition: all .5s ease-in-out;
    line-height: 0;
    background-color: transparent;
    transform: translate(-1em, -0.15em);
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      border-radius: 2em;
      transition: all .5s ease-in-out;
    }
  
    &:before {
        box-shadow: 0 0 0 0;
        width: 2em;
        height: 0.3em;
        background: currentColor;
        transform: translate(0, 0) rotate(225deg);
        top: 50%;
        left: 50%;
    }
    &:after {
        box-shadow: 0 0 0 0;
        width: 2em;
        height: 0.3em;
        background: currentColor;
        transform: translate(0, 0) rotate(-225deg);
        top: 50%;
        left: 50%;
    }
  }
  