.newsletter-headline {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #D5A676;
  }
  .newsletter-subtitle {
    text-align: center;
    color: #fff;
  }

  .newsletter-parent {
    max-width: 790px;
    margin: 0 auto;
    padding: 1rem;
  }
  @media all and (max-width: 991px) {
    .newsletter-headline, .newsletter-subtitle {
      text-align: center;
    }
  }
  .ch-newsletter { 
    width: 100%; 
    padding-bottom: 50px;
  } 
  .ch-newsletter form {
    margin-top: 30px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }
  
  .newsletter-input {
    border: 1px solid #e0e0e0;
    color: #333; 
    flex: 1; 
    font-size: 0.9rem;
    line-height: 1.6875;
    padding: .5625em 1.5em; 
    margin-right: 10px;
    margin-bottom: 10px;
  } 
  @media all and (max-width: 631px) {
    .ch-newsletter form {
      flex-direction: column;
    }
    .newsletter-input {
      margin-right: 0;
    }
  }
  .newsletter-input:focus, .newsletter-input:active {
    border-color: #D5A676;
    outline: none;
  }
  .newsletter-submit {
    border: 0;
    border: 0; 
    font-size: 16px;
    line-height: 1.6875;
    padding: .5625em 1.5em;
    cursor: pointer;
  }
  .newsletter-submit2 {
    color: #fff;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    border: 0; 
    font-size: 16px;
    line-height: 1.6875;
    padding: .5625em 1.5em;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
    max-width: 100%;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    transition: all 0.2s ease;
    background: #D5A676;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .newsletter-submit2:hover {
    background: #df903f;
  }
  