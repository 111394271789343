.p16flex {
    list-style: none;
    border-bottom: 1px solid #EBEBEB;  
    padding: 16px;  
    align-items: center;  
    justify-content: space-between;  
    display: flex;
    margin-bottom: 16px;
  }


  
.ult2 {
    display: flex;
    align-items: center;
  }
  
  .mr8ss {
  
    width: 16px;
    height: 14px; 
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;  
    margin-right: 8px;
    top: 0;
  
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #4c4c4c;
      border-radius: 5px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
  
      &:nth-child(1) {
          top: 0;
          width: 100%;
      }
      &:nth-child(4) {
          top: 12px;
          width: 100%; 
          left: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 6px;
      }
  
     
      }
    }
   
  
    .p12in {
  
  
      span {
        p{ 
          margin: 0;
          font-size: 20px;
          color: #D5A676;
          font-weight: 400;
        }
      }
    }


    .exitButtonOnNavbar {   
        color: #333333; 
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    
    .closingTag {
      width: 20px;
      height: 20px; 
    }
    
  