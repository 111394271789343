.LinkDirectionActive {
    color:#df903f;
  } 


  .LinkDirection {
    margin: 0px 16px 6px;
  padding: 8px;
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  line-height: 20px;
  border-radius: 8px;
  color: rgb(45, 41, 38); 
  text-decoration: none; 
  font-size: 0.9rem; 
  } 

  .LinkDirection:hover {
    background-color: rgb(246, 246, 246);
  } 