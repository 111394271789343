
 

.Hamburger {
  width: 24px;
  height: 22.5px; 
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer; 

 
    @media screen and (min-width: 1000px) {
    
      display: none;
   
  };
 

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #D5A676;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
        top: 0;
        width: 100%;
    }
    &:nth-child(4) {
        top: 18px;
        width: 100%; 
        left: 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

   
    }
  }

  .bik6Ent {
    width: 44px;
    padding: 10px;
    cursor: pointer;
  }


  @media only screen and (min-width: 1000px) {
    .bik6Ent {
      display: none;
    }
  }
  