
.mojZ {
    max-width: 1216px;
    margin: 0 auto;
    padding: 0 4rem;
}

.lock7 {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto; 
    text-align: center;
    padding: 1rem 24px 4rem 24px;
  }
  .glu8en { 
      font-size: 2rem;
      color: #fff; 
      position: relative; 
  }
  
  .glu8en::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 4px solid #D5A676;
    -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }
   
  /////////////////////////////////////////////////////////////////////
  /// //////////////////////////////////////////////////////////////
  /// ////////////////////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////
  /// /////////////////////////////////////////////////////////////////
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  /// 
  
  
  
  .j9_description {
    padding-left: 4em;
      padding-right: 4em;
      background: #FDFAF7;
      max-width: 1344px;
      margin: 0 auto;
      padding-bottom: 51px;
    
    #spec22 {
      font-size: 1.6em;
      margin-bottom: 1em;
      margin-top: 50px;
      color: #6cd7bd;
    }
   
  }
  
   
   
  .isa076e {
    display: grid;
    position: relative;
    grid-gap: 20px;
    flex-grow: 1;
    max-width: 1536px;
    min-width: auto;
    align-self: stretch;
    flex-basis: auto;
    min-height: auto;
    align-items: flex-start;
    flex-shrink: 1; 
    justify-content: flex-start; 
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  
  .nts21t {
    width: 100%;
    margin: 5px 0;
    background-color: rgba(0, 0, 0, 0.1);
    background-color: #D5A676;
    height: 1px;
    font-feature-settings: "tnum", "tnum", "tnum";
    box-sizing: border-box;
    color: #222;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
  }
  
  .war72yt {
    span {
      p {
        color: #fff;
        text-align: center;
        margin: 0;
        font-weight: 600;
        font-size: 1em;
      }
    }
  }
  
  .oew21j {
    span {
      p {
        color: #fff;
        text-align: center;
        margin: 0;
        font-size: 0.9em;
  
         a {
          text-decoration: none;
          color: #D5A676;
         }
      }
    }
  }
  
  #returnPolicy { 
      p {
        margin: 0;
        font-size: 0.95em;
  
         a {
          text-decoration: none;
          color: #D5A676;
         }
      }
    }
   
  .table {
    border: 1px solid #D5A676;
    background: #2B2728;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    color: #fff;
  } 
  
  .table tr { 
    border-top: 1px solid #D5A676;
    border-bottom: 1px solid #D5A676; 
  }
  
  .table th {
    width: 20% !important;
    white-space: normal;
    word-wrap: break-word; 
  }
  
  .table th,
  .table td { 
    text-align: left;
    padding: 7px 14px 6px;
    border: 1px solid #D5A676;
  }
  .table td {
    width: 100%; 
  }
  
  .table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: #D5A676;
  }
  

  #tabletr { 
    letter-spacing: .1em;
    justify-content: center; 
    text-align: center;
    display: table-caption;
    padding: 7px 14px 6px;
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    border: 1px solid #D5A676;
  }
   
  
  
  .del521t {
    text-align: center; 
  
    svg {
      position: relative;
      top: 2px;
    }
  
    span {
      display: inline-block;
      margin-left: 3px;
  
      #pp26gn4 {
        display: inline-block;
        color: #202530;
        font-weight: bold;
        margin: 0;
        font-size: 0.9em;
      }
      p {
        display: inline-block;
         margin: 0;
         color: #202530;
         font-size: 0.9em;
      }
    }
  }
  .tp90lk4 {
    margin-top: 1em;
    div {
      display: inline-block;
      height: 15px;
      width: 15px;
      top: 3px;
      position: relative;
      margin-right: 4px;
    }
    span {
      display: inline-block;
      
      p {
        margin: 0;
        color: #007600;
      }
    }
  } 
  
  .eet52y {
    height: 478px;
    text-align: right;
  
    img {
      position:relative;
      width: 50px;
      height: 50px; 
    }
  }
   
  
  .eet52ys  {
    border-bottom-color: #c9c6c6;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    text-align: left;
  }
  .und84el2 {
    display: flex;
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 0;
      justify-content: flex-start;
  
  } 
  .uewwd2v {
    
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-basis: auto;
    flex-shrink: 1;
    flex-direction: column;
    border-top-color: #c9c6c6;
    border-top-style: solid;
    border-top-width: 1px;
  }
  
  .rid846a{
    cursor: pointer;
    display: flex;
    position: relative;
    min-width: auto;
    flex-basis: auto;
    min-height: auto;
    align-items: center;
    flex-shrink: 0;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    justify-content: space-between;
  }
  
  .b3b885{
    color: #333333;
    width: 100%;
    font-size: 14px;
    flex-basis: auto;
    flex-shrink: 0;
    line-height: 24px;
    padding-bottom: 12px;
    overflow-wrap: break-word;
    cursor: pointer;
  
    ul {
      margin: 0;
      padding-left: 12px;
    }
  
    span {
      p {
        margin: 0;
        color: #333333;
      }
    }
  }
  
  .c90f07 {
    color: #333333;
    font-size: 1em;
    flex-basis: auto;
    flex-shrink: 0;
    line-height: 25px;
    overflow-wrap: break-word;
  
    span {
      p {
        margin: 0;
        
        color: #333333;
      }
    }
  }
  
  .is22tl {
    margin-top: 1.2em;
    width: 100%;
  cursor: pointer;
  display: flex; 
  outline: none;
  overflow: hidden;
  position: relative;
  transform: none;
  flex-basis: auto;
  flex-shrink: 0;
  padding-top: 10px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 10px;
  background-color: #6CD7BDFF;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-color: transparent;
  }
  
  .k63k7s0 {
    position: sticky;
    top: 77.8px;
  }
  .lck462o {
    text-transform: capitalize;
    color: #2F394EFF;
    width: auto;
    font-size: 20px;
    align-self: stretch;
    flex-basis: auto;
    flex-shrink: 0;
    line-height: 40px;
    margin-bottom: 10px;
    padding-right: 0px;  
  
    span {
      p {
        margin: 0;
       color: #2F394EFF;
       font-size: 1.6em; 
       line-height: inherit;
      }
    }
  }
  
  .is54tl { 
    margin-top: 1.2em;
    width: 100%;
  cursor: pointer;
  display: flex; 
  outline: none;
  overflow: hidden;
  position: relative;
  transform: none;
  flex-basis: auto;
  flex-shrink: 0;
  padding-top: 10px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 10px;
  background-color: #6CD7BDFF;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-color: transparent;
  }
  
  .is54tl:focus {
  outline: none;
  }
  
  .is54tl:hover {
  background-color: #09f2b9;
  text-decoration: none;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  color: #ffffff; 
  transition: all 200ms ease-in-out;
  
  }
  .ode8567 {
    color: #FFFFFF;
    cursor: pointer;
    flex-grow: 1;
    font-size: 1em;
    flex-basis: auto;
    flex-shrink: 1;
    font-family: "Larsseit Bold", sans-serif;
    font-weight: 400;
    line-height: 24px;
    padding-left: 10px;
    overflow-wrap: break-word;
    padding-right: 10px;
    letter-spacing: 1px;
  
    span {
        p {
            margin: 0;
            color: #FFFFFF;
        }
    }
  }
    
  .ho8216t {
    display: flex;
    position: relative;
    max-width: 100%;
    min-width: auto;
    align-self: stretch;
    flex-basis: auto; 
    min-height: auto;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-top: 2em;
  }
  
  .l5m227 {
    font-size: 1.1em;
      flex-basis: auto;
      flex-shrink: 0;
      font-weight: 400;
      line-height: 24px; 
      overflow-wrap: break-word;
     
  
      span {
  
        p {
          margin: 0; 
          color: #2F394EFF;
          font-size: 0.9em;
        }
  
        #xy42p9 {
          padding-top: 2em;
        }
      }
  }
  .ra742gn {
    display: inline-flex;
      align-items: center;
      gap: 2px;
      vertical-align: middle;
  
      span { 
        display: inline-block;
        p{
          margin: 0;
          margin-left: 5px;
          line-height: 0px;
          font-size: 0.8em;
          position: relative;
          top: 0px;
        }
      }
  }
  .s4gj9k {
    width: 100%; 
    padding-top: 103.6px;
    padding-left: 4em;
    padding-right: 4em;
    background: #FDFAF7; 
  }
  
  .containers {
      max-width: 1216px;
      margin: 0 auto;
      height: auto; 
      background: #FDFAF7; 
      display: flex;
      justify-content: space-between; 
    }
    
    .lefts {
      width: 50%;
      padding-top: 30px;
      padding-right: 30px;
    } 
    .flex {
      display: flex;
      justify-content: space-between;
    }
  
    .option {
      display: flex;
    flex-wrap: wrap; 
      width: 100%;
      justify-content: center;
      text-align: center;
  
      img {
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.27);
        -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.27);
        -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.27);
        margin: 5px;
      }
  
      img:hover {
        box-shadow: 0px 0px 2px 2px #6cd7bd;
        -webkit-box-shadow: 0px 0px 2px 2px #6cd7bd;
        -moz-box-shadow: 0px 0px 2px 2px #6cd7bd;
      } 
    }
    
    .flex1 {
      display: flex;
    }
    
    .mainImage { 
      height: 420px;
      max-height: 600px;
      display: flex;
      row-gap: 12px;
      overflow: hidden;
      position:relative;
      flex-grow: 1;
      min-width: auto;
      align-self: stretch;
      column-gap: 12px;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 1;
      padding-bottom: 12px;
      justify-content: center;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
  
      picture {
        display: flex;
        overflow: hidden;
        flex-grow: 1;
        max-width: none;
        min-width: 0;
        align-self: stretch;
        flex-basis: auto;
        min-height: 0;
        object-fit: cover;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        flex-shrink: 1;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
  
        img {
          height: 100%;
      margin: 0;
      display: block;
      padding: 0;
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
      min-height: 100%;
      object-fit: fill;
        }
      }
  
       
    }
    
    .option img {
      width: 70px;
    height: 70px;
      cursor: pointer;
    }
    
    .rights {
      padding-top: 30px;
      padding-left: 30px;
      width: 50%;
     
      #diffuserPrice {
        display: inline-block;
        font-weight: bold;
      }
  
      #diffuserPercentage {
        display: inline-block;
        margin-left: 10px;
        font-size: 1em;
      }
  
      #diffuserDiscount {
        display: inline-block;
        margin-left: 10px;
        color: #6b6760;
        font-size: 1em;
        text-decoration: line-through;
        text-decoration-thickness: 1px;
        font-weight: bold;
      }
      h3 {
        color: #2F394EFF;
        margin: 0 0 10px 0;
        font-size: 2em;
        font-family: 'Poppins';
      }
  
      #parfumesBrand {
        color: #240090;
        font-size: 20px;
      }
      
      h5,
      p,
      small {
        color: #837D7C;
      }
      
      h4 {
        color: red;
      }
  
      #diffuserPercentage {
        color: #202530;
      } 
      
      h5 {
        font-size: 15px;
      }
    
    }
    
    
    #colorChosen {
      color: #2F394EFF;
      font-size: 1em;
      font-weight: 400;
  
      #ra84t2 {
        display: inline-block;
        color: black;
        font-weight: 700;
        margin: 0;
        line-height: 0;
      }
    }
    .colorsWrapper { 
      display: grid; 
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)); 
      row-gap: 10px;
      position: relative; 
      column-gap: 10px;
      
    }
    
    .firstColor {
      display: flex;
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      flex-basis: auto;
      min-height: auto;
      flex-shrink: 0;
      flex-direction: column;
    }
  
    .colorChoicesWrapper {
      width: 130px;
      height: 65px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.27);
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.27);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.27); 
      cursor: pointer; 
    }
    .colorChoicesWrapper:hover {
      box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -webkit-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -moz-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
    }
    .colorChoicesWrapper:active {
      box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -webkit-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -moz-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
    }
  
    .colorChoicesWrapper .active {
      box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -webkit-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
      -moz-box-shadow: 0px 0px 2px 2px rgba(36, 0, 144, 0.41);
    }
  
   
  
    .colorChoicesImage {
      display: flex;
      position: relative;
      flex-grow: 1;
      min-width: auto;
      align-self: stretch;
      flex-basis: 0;
      min-height: auto;
      transition: inherit;
      align-items: center;
      flex-shrink: 1;
      justify-content: center;
      
      .il1in5 {
  
        width: 75.4px;
        height: 75.4px;
      align-self: center;
      flex-basis: auto;
      object-fit: fill;
      transition: inherit;
      flex-shrink: 0;
  
        img {
          height: 100%;
          margin: 0;
          display: block;
          padding: 0;
          max-width: 100%;
          min-width: 100%;
          max-height: 100%;
          min-height: 100%;
          object-fit: fill;
          transition: inherit;
        }
      }
   
    }
  
     
  .s2_divider_Wrapper {
    max-width: 1216px; 
    margin: 0 auto;
  }
  
    .s2_divider {
      width: 100%;
      margin: 5em 0;
      background-color: rgba(0, 0, 0, 0.1);
      background-color: #c9c6c6;
      height: 1px;
      font-feature-settings: "tnum", "tnum", "tnum", "tnum";
      box-sizing: border-box;
      color: #222;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
    }
    .colorChoicesText {
      display: inline-block;
      width: 65px;
      height: 65px;
      vertical-align: top;
      text-align: center;
  
      #choicesTextPortalbe {
        color: #240090;
        line-height: 65px;
        margin: 0; 
      }
    }
  
    .addIncrease {
      display: flex;
    }
    
  
    .opz183t {
      padding-bottom: 50px;
      display: grid;
      position: relative;
      flex-grow: 1;
      max-width: 1536px;
      min-width: auto;
      align-self: stretch;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 1; 
      justify-content: flex-start;
      background-color: #FDFAF7;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .econ255 {
      width: 100%;
      height: 100%;
      display: flex; 
      position: relative;
      max-width: 100%;
      min-width: auto;
      transform: none;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 0;
      margin-left: 0%; 
      flex-direction: column;
      justify-content: center;
      background-color: #FDFAF7;
    }
  
    .ror300s {
      max-height: 31.25rem;
      display: flex;
      row-gap: 5%;
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      column-gap: 5%;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;
      background-color: #FDFAF7;
  
      picture {
        max-height: 31.25rem;
        width: 699px;
        display: flex;
        flex-grow: 1;
        min-width: 0;
        flex-basis: auto;
        min-height: 0;
        object-fit: cover;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        flex-shrink: 1; 
        padding-left: 0em; 
        object-position: center center;
  
         img {
          height: 100%;
      margin: 0;
      display: block;
      padding: 0;
      max-width: 100%;
      min-width: 100%;
      max-height: 31.25rem;
      min-height: 100%;
      object-fit: fill;
      object-position: center center;
         }
      }
    }
  
    .opz183ts {
      padding-bottom: 30px;
      display: grid;
      position: relative;
      flex-grow: 1;
      max-width: 1536px;
      min-width: auto;
      align-self: stretch;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 1; 
      justify-content: flex-start;
      background-color: #FDFAF7;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .econ255s {
      width: 100%;
      height: 100%;
      display: flex; 
      position: relative;
      max-width: 100%;
      min-width: auto;
      transform: none;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 0;
      margin-left: 0%; 
      flex-direction: column;
      justify-content: center;
      background-color: #FDFAF7;
    }
  
    .ror300st {
      order: 2;
      max-height: 31.25rem;
      display: flex;
      row-gap: 5%;
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      column-gap: 5%;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;
      background-color: #FDFAF7;
  
      picture {
        max-height: 31.25rem;
        width: 699px;
        display: flex;
        flex-grow: 1;
        min-width: 0;
        flex-basis: auto;
        min-height: 0;
        object-fit: cover;
        overflow-x: auto;
        overflow-y: hidden;
        align-items: center;
        flex-shrink: 1; 
        padding-left: 0em; 
        object-position: center center;
  
         img {
          height: 100%;
      margin: 0;
      display: block;
      padding: 0;
      max-width: 100%;
      min-width: 100%;
      max-height: 31.25rem;
      min-height: 100%;
      object-fit: fill;
      object-position: center center;
         }
      }
    }
  
    .d9bf021an {
      padding-top: 30px;
      padding-bottom: 50px;
      color: #6CD7BDFF;
      font-size: 1.6em;
      flex-basis: auto;
      text-align: center;
      flex-shrink: 0;
      font-family: 'Poppins';
      line-height: 1.4em; 
      overflow-wrap: break-word;
      text-align-last: center;
      font-weight: 800;
      letter-spacing: 2px;
  
       span {
        p {
          margin: 0;
        }
       }
    }
  
    .d9bf021a {
      color: #6CD7BDFF;
      font-size: 1.6em;
      flex-basis: auto;
      text-align: center;
      flex-shrink: 0;
      font-family: 'Poppins';
      line-height: 1.4em; 
      overflow-wrap: break-word;
      text-align-last: center;
      font-weight: 800;
      letter-spacing: 2px;
  
       span {
        p {
          margin: 0;
        }
       }
    }
  
    .r1b1kutr {
      top: -822.2857142857147px;
      left: 66.65%;
      color: #60646c;
      width: 100%;
      z-index: 1;
      position: static;
      flex-grow: 1;
      font-size: 1em;
      transform: none;
      flex-basis: auto;
      text-align: center;
      flex-shrink: 1;
      font-family: quasimoda, sans-serif;
      line-height: 1.4em;
      overflow-wrap: break-word;
      text-align-last: center;
      margin-top: 15px;
    
      span {
        p {
          margin: 0;
        }
      }
    }
  
  
    .dist2001 {
      width: 100%;
      margin-top: 2em;
      margin-bottom: 2em;
      background-color: rgba(0, 0, 0, 0.1);
      background-color: #c9c6c6;
      height: 1px;
      font-feature-settings: "tnum", "tnum", "tnum", "tnum";
      box-sizing: border-box;
      color: #222;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
    }
  
  
    .ing611 { 
      display: grid;
      position: relative;
      flex-grow: 1; 
      min-width: auto;
      align-self: stretch;
      flex-basis: auto;
      min-height: auto;
      align-items: flex-start;
      flex-shrink: 1;
      justify-content: flex-start;
      background-color: #FDFAF7;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  
    .jg62h { 
      display: flex; 
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      column-gap: 10px;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;
      background-color: #FDFAF7;
      padding: 10px;
  
      svg {
        height: 30px;
        width: 30px;
        fill: #596277;
      }
      
      span {
        p {
          margin: 0;
          color: #2F394EFF;
        }
      }
    }
    
  
    .ccb211 {
      display: flex; 
      position: relative;
      max-width: 100%;
      min-width: auto;
      align-self: stretch;
      column-gap: 10px;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;
      background-color: #FDFAF7;
      padding: 10px;
  
      svg {
        width: 30px;
        height: 30px;
        fill: #596277;
      }
      span {
        p {
          margin: 0;
          color: #2F394EFF;
        }
      }
    }
  
    .zc5tx {
      display: flex; 
      position: relative;
      max-width: 100%;
      min-width: 200px;
      align-self: stretch;
      column-gap: 10px;
      flex-basis: auto;
      min-height: auto;
      align-items: center;
      flex-shrink: 0;
      justify-content: flex-start;
      background-color: #FDFAF7;
      padding: 10px;
      svg {
        width: 30px;
        height: 30px;
        fill: #596277;
      }
  
      span {
        p {
          margin: 0;
          color: #2F394EFF;
        }
      }
    }
  
  
    .ing622 { 
      border: 1px solid #EAECF0;
      border-radius: 8px;
      margin-bottom: 15px;
      flex-direction: column;
      align-self: stretch;
      font-family: "Inter";
    }
  
    .jg62g {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 8px 16px; 
      background:#F2F4F7;
      text-align: center; 
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      word-wrap: break-word;
      font-family: "Inter";
  
      span {
        p {
          color: #596277;
          margin: 0;
        }
      }
    }
  
    .ccb222 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 5px; 
      gap: 13px;
      flex-direction: row;
    }
  
  
    .ial90 {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start; 
      gap: 5px;
      align-self: stretch;
  
      div {
        svg {
          height: 25px;
      width: 25px;
      fill: #596277;
        }
      }
    }
  
  
    .lai58ic {
      width: 36px;
      height: 36px;
      padding: 0;
      background: #f2f4f7;  
      border-radius: 28px;
      border: 4px solid #f9fafb; 
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      padding: 4px;
    }
  
    .lai58tx { 
      font-size: 14px;
      font-weight: 400;
      word-wrap: break-word;
      font-family: "Poppins";
      span {
        p {
          font-size: 13px;
          margin: 0;
          color: #596277;
        }
      }
     
    }
  
  .ialdivider { 
      background-color: #EBEDF1;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      width: 1px;
      height: 36px;
  }
  
  
  .paymentoptions {
    display: flex;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
    align-self: stretch;
  }
  
  .paymentitems {
    display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 0;  
      border: 1px solid #EBEDF1;
      border-radius: 6px;
      height: 35px;
  }
  //   label,
    // .add span,
    // .color span {
    //   width: 25px;
    //   height: 25px;
    //   background: #000;
    //   border-radius: 50%;
    //   margin: 20px 10px 20px 0;
    // }
    
    // .color span:nth-child(2) {
    //   background: #EDEDED;
    // }
    
    // .color span:nth-child(3) {
    //   background: #D5D6D8;
    // }
    
    // .color span:nth-child(4) {
    //   background: #EFE0DE;
    // }
    
    // .color span:nth-child(5) {
    //   background: #AB8ED1;
    // }
    
    // .color span:nth-child(6) {
    //   background: #F04D44;
    // }
    
    .addIncrease label,
    .addIncrease span {
      background: none;
      border: 1px solid #C1908B;
      color: #C1908B;
      text-align: center;
      line-height: 25px;
    }
    
    .addIncrease label {
      padding: 10px 30px 0 20px;
      border-radius: 50px;
      line-height: 0;
    }
     
    .addToCart {
      width: 100%;
      padding: 10px;
      border: none;
      outline: none;
      background: #C1908B;
      color: white;
      margin-top: 20%;
      border-radius: 30px;
    }
  
    .product_color_wrapper {
      margin-right: 10px;
      border: 1px solid #E0E0E0;
      display: table;
     overflow: hidden;
     text-align: center;
     vertical-align: middle;
     justify-content: center;
     max-height: 50px;
     max-width: 101px;
    }
  
    .product_color_left {
      float: left;
      padding: 5px;
      div {
        margin: 0; 
        img {
          height: 36px;
          width: 36px;
        }
      }
    }
  
    .product_color_right { 
      min-height: 46px;
      display: table-cell;
      vertical-align: middle;
      padding: 5px;
      div {
        margin: 0;
        p {
          margin: 0;
          font-size: 14px;
          line-height: inherit;
      
        }
      }
  
    
    }
    .backToProducts {
      margin-bottom: 2rem;
      #span {
        position: relative;
        display: inline-block;
        float: left;
        top: 3px;
        margin-right: 5px;
        color: #626060; 
      }
  
      a {
        color:#626060;
      }
    }
   
    
    @media only screen and (max-width:1000px) {
      .s4gj9k {
        padding-left: 2em;
      padding-right: 2em;
      }
  
      .j9_description {
        max-width: 1280px;
        padding-left: 2em;
        padding-right: 2em;
      }
    
     
    }
  
    @media only screen and (max-width:800px) {
        #tabletr, .table th, .table td { 
            padding: 7px 2px 6px;

        }
     .mojZ {
     padding: 0;
     }
  
      .j9_description {
        padding: 0;
      }
      .s2_divider {
        margin: 2em 0;
      }
  
      .lefts, .rights {
        width: 100%;
        padding: 0;
        margin-top: 1em;
      }
   
      .mainImage {
        max-height: 420px;
        height: inherit;
      }
    
      .containers {
        flex-direction: column;
      }
  
      .eet52y {
        height: auto; 
        margin-bottom: 2em;
        }
  
        .econ255 {
          padding: 20px 0;
          background: #EEF2F8FF;
          padding-left: 2em;
          padding-right: 2em;
    }
  
  
    .econ255s { 
      order: 2;
      background: #EEF2F8FF;
      padding: 20px 0;
      padding-left: 2em;
      padding-right: 2em;
  }
    .opz183t {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
    }
  
    .opz183ts {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
    }
  
    .ror300s {
      padding-left: 2em;
      padding-right: 2em;
  
      picture {
        width: 100%;
      }
     
    }
  
    .ror300st {
      padding-left: 2em;
      padding-right: 2em;
      order: 1;
      picture {
        width: 100%;
      }
     
    }
  
  .j9_descriptionAccord {
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 51px;
  }
    .pl428te {
      padding-left: 2em;
      padding-right: 2em;
    }
  
    .d9bf021a { 
      padding-bottom: 0;
    }
  
    .d9bf021an {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  
    @media only screen and (max-width:700px) {
  .isa076e {
  grid-template-columns: 1fr;
  }
    }
    @media only screen and (max-width:550px) {
  
  
      .colorsWrapper { 
        display: grid; 
        grid-template-columns: repeat(auto-fit, minmax(69.5px, 1fr)); 
      }
  
      .j9_descriptionAccord {
        padding-left: 1em;
        padding-right: 1em;
      }
  
      .pl428te {
        padding-left: 1em;
        padding-right: 1em;
      }
  .table td {
    padding: 4px 9px 4px;
    font-size: 0.9em;
  }
  
  .table th {
    font-size: 0.75em;
  }
      .j9_description {   
      
      
       
        span {
        
          #spec22 {
            font-size: 1.4em;
  
          }
          p {
            font-size: 1em;
            
          }
        }
      }
      .s4gj9k {
        padding-left: 1em;
      padding-right: 1em;
      }
     .mainImage {
      height: 350px;
     }
  
     .option img {
      width: 45px;
      height: 45px;
     }
  
     .lck462o {  
      font-size: inherit;
      line-height: 23px;
      span {
        p { 
         font-size: 1.4em;  
        }
      }
    }
  
    #diffuserPrice {
      font-size: 1.3em;
    }
  
    #diffuserDiscount {
      font-size: 0.9em;
    }
    .ho8216t { 
      padding-top: 0;
    }
  
    .l5m227 { 
    
        span { 
          #xy42p9 {
            padding-top: 1em;
          }
        }
    }
  .is54tl { 
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .colorChoicesImage {
  .il1in5 { 
    width: 55.4px;
    height: 55.4px;
  }
  }
  
  .del521t { 
    span { 
  
      #pp26gn4 { 
        font-size: 0.8em;
      }
      p { 
         font-size: 0.8em;
      }
    }
  }
  
  .c90f07 {
    font-size: 1em;
  }
  
  .rid846a{ 
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px; 
  }
  
  
  .econ255 {
    padding-left: 1em;
    padding-right: 1em;
  }
  
  .econ255s {
    padding-left: 1em;
    padding-right: 1em;
  }
  
  .ror300s {
    padding-left: 1em;
    padding-right: 1em;
  }
  
  .ror300st {
    padding-left: 1em;
    padding-right: 1em;
  }
  
  .jg62h, .ccb211, .zc5tx {
    padding: 5px;
  }
  
  
  }
  
    
  @media only screen and (max-width:450px) {
  
  
    .ccb222 {
      display: grid;
      grid-template-columns: repeat(1, 1fr 0fr);
      
    }
  
  }