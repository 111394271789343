.privacyPolicy {
    padding-top: 75.2px;
    background: radial-gradient(circle, #383335 0%, black 100%);
}


.priv482 {
    padding: 4rem 1rem;
    max-width: 600px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
    flex-direction: column;

}


.privacyTitle {
    text-align: center;
    margin-bottom: 50px;
    font-size: 1.4em;
    font-weight: bold;
    color: #D5A676;
}


.privacytx {

   

    h5 {
        font-weight: bold;
        font-size: 1.2em;
        color: #D5A676;
    }
    span {
        p {
            color: #fff;
            font-size: 0.9em;
            line-height: 1.5rem;
            margin: 0;
        }
    }

    ul {
        padding-left: 1em;
        margin-bottom: 0; 

        li {
            padding-bottom: 0.5em;
        }
       
       
    
    }
}

#ul885 {
    list-style-type: none;
}

#sice56 {
   margin: 0;
   display: inline;
   color: #fff;
   font-weight: bold;
   padding-right: 6px;
}

#ecis56 {
    margin: 0;
    display: inline;
    color: #fff;
    font-size: 0.9rem;
}


.informationcontact {
    display: flex;
    color: #fff;
    margin: 0;
    align-items: start;
    font-size: 0.95rem;
    font-family: 'Poppins';
    
    p {
        margin: 0;
        color: #fff;
    }
}

#pppfff {
    color: #fff;
}

#ppolicy {
    text-decoration: none;
    color: #D5A676;
}

#ppolicy:hover {
    color: #D5A676;
}