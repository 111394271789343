.wrapTest {
    height: 1500px;
    width: 100%;
    background-color: teal;
    filter: blur(8px);
}


.wrapTest2 {
    height: 1500px;
    width: 100%;
    background: rgb(246, 248, 249);
    filter: none;
}