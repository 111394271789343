.penman {
  background: rgb(56,51,53);
background: radial-gradient(circle, rgba(56,51,53,1) 0%, rgba(0,0,0,1) 100%); 
}


.lock7 {
  width: 100%;
  max-width: 1216px;
  margin: 0 auto; 
  text-align: center;
  padding: 60px 24px 30px 24px; 
}
.glu8en { 
    font-size: 2rem;
    color: #fff; 
    position: relative; 
}

.glu8en::after {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  aspect-ratio: 3 / 1;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 4px solid #D5A676;
  -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
  clip-path: polygon(0 0, 50% 50%, 100% 0);
}


.widget01 {
  padding-top: 5rem;
}

.widget02 {
  height: 20rem;
    width: 100%; 
    position:relative;
    margin: 0 auto; 
}

#sum1l {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  object-position: 17% 0%;
  object-fit: cover;
}

.mcPaj {
  position: absolute;
  text-align: left; 
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: .5rem;
  padding: 2rem;
  grid-gap: .2rem;
  gap: .2rem;
  display: grid;
  z-index: 2;
  left: 60%;
  transform: translate(-30%, 17%);
  top: -15%;
  height: auto;
  min-width: 34rem;
}

#werk57 {
  background-color: transparent !important;
    color: #D5A676; 
    font-weight: 500;
    font-size: 1rem;
}

#werk58 {
  background-color: transparent !important;
  color: #fff; 
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 1rem;

  i {
    color: #D5A676;
    font-weight: 600;
  }
}

#its33 {
  font-size: 1rem;
  color: #fff;
  padding-bottom: 1rem;
  font-style: italic;
 

  i {
    font-weight: 500;
  }
}

.rve41 {
  text-decoration: none;
  width: 50%;
  margin: 0 auto;
  display: block;
  color: #fff;
  background: #0000;
    border: 1px solid #d5a676;
    border-radius: 1rem;  
  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 1rem;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

.rve41:hover {
  color: #d5a676;
}



@media only screen and (max-width: 1000px) { 

.mcPaj {
left: 48%;
}

}


@media only screen and (max-width: 770px) { 
  #sum1l { 
    object-position: 0% 0%;

  }
  .mcPaj {
  position: relative;
  width: 90%;
  margin: 0 auto;
  min-width: inherit; 
  left: 0;
  top: 9%;
  transform: inherit;
  padding: 0;
  }
 
  #werk58 {
    font-size: 1.2rem;
  }
  
  }
  
  @media only screen and (max-width: 500px) { 
#sum1l {
  object-position: 5% 0;
}
  }
  @media only screen and (max-width: 400px) { 

    #sum1l {
      object-position: 10% 0;
    }
    .mcPaj {
      top: 8%;
    }
    .rve41 {
      margin-top: 0;
    }

    #its33 {
      font-size: 0.9rem;
    }

    #wek58 {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 370px) { 

    .mcPaj {
      top: 4%;
    }
 

    
  }