.productWrapper {
    width: 100%; 
    background: rgb(56, 51, 53);
    background: radial-gradient(circle, rgb(56, 51, 53) 0%, rgb(0, 0, 0) 100%);
}

.productFlex {
    padding-top: 75.2px;
    position: relative;
    width: 100%;
} 

.med97 {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    list-style: none outside none; 
    white-space: nowrap;
    overflow: auto;
    display: flex;
    border-top: 1px solid #D5A676;
    padding-left: 1rem;
}



.med97 li:nth-child(1):after {
    content: "/";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-decoration: inherit;
    padding: 0 0.3rem;
    color: #828282; 
}

#hpG {

    a {

        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 1px;
        color: #828282;
        text-decoration: none;
    }

    a:hover {
        color: #D5A676;
    }
}
 

#ppG { 
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: 1px;
        color: #D5A676;
        text-decoration: none; 
} 
.descR {
    display: flex;
    flex-direction: row;
    padding-bottom: 0rem;
    border-bottom: 0px solid #f2f2f2;
    margin-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 273px;
    padding-top: 1rem;
}

.unt24 {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
}

.headPic {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

#headPho {
    border: 0;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%; 
}
.heD { 
    color: #333;
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    font-size: 2.3rem;
    padding-bottom: 2rem;
    margin-bottom: 1.5rem; 
    line-height: 1;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    position: relative;
}

.heD::after {
    content: '';
    width: 100px;
    bottom: 0;
    left: 0;
    height: 2px;
    background: #d5a676;
    position: absolute;

}

.headlineT {
    display: block;
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    color: #D5A676;
    
}

.headlineB {
    padding-left: 1rem;
    font-size: 2.4rem;
    margin-top: 0.5rem;
    display: block;
    color: white;
    font-weight: 300;
    } 


#headLP {
    color: white;
    font-size: 0.8rem;
}

.allPContainer {
  max-width: 1216px;
  margin: 0 auto;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 68px;
}

.allPcounts {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
}

#po13i {
    color: #777;
    font-size: 1rem;
}


@media only screen and (max-width: 950px) { 

.descR {
    flex-direction: column;
    max-height: inherit;
}
.unt24 {
    padding-right: 0;
    padding-bottom: 1rem;
}

.headPic {
    max-width: 100%;
}

.heD {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.headlineB {
    font-size: 2rem;
}

.headlineT {
    font-size: 0.9rem;
    line-height: inherit;
}

}

@media only screen and (max-width: 500px) { 

    .headPic {
        max-width: 100%;
        height: 10rem;
        flex: inherit;
    
    }

    #headPho {
        object-fit: cover;
        background-position: 50% 50%;
        width: 100%;
        height: 100%;
        object-position: 75% 50%;
    }
    
}

@media only screen and (max-width:800px) {
    #po13i {
        font-size: 0.85rem;
    }

}