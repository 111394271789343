.penman1 {
  background: rgb(56,51,53);
  background: radial-gradient(circle, rgba(56,51,53,1) 0%, rgba(0,0,0,1) 100%);
    width: 100%; 
}

 
.glu8en { 
      font-size: 2rem;
      color: #fff; 
      position: relative;
  }
  
  .glu8en::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    aspect-ratio: 3 / 1;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 4px solid #D5A676;
    -webkit-clip-path: polygon(0 0, 50% 50%, 100% 0);
    clip-path: polygon(0 0, 50% 50%, 100% 0);
  }
  
.lock7 {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 24px 30px 24px;
}