
@import '../../../../global/styles';
 

.ndleRst {
  position: absolute;
  overflow: hidden;
width: 100%;
height: 100dvh;
left: 0;
top: 0;   
background-color: transparent;
z-index:1041;
visibility: hidden; 

@mixin for-hamburger {
  @media screen and (min-width: 1000px) {
   display: none;
  } 
  }
};

.Menu {
  position: absolute;
  overflow: hidden;
width: 20rem;
height: 100dvh;
left: 0;
top: 0; 
vertical-align: top;
justify-content: center;
align-items: center; 
background-color:#FDFAF7;
z-index:1042;
visibility: hidden;
transform: translateX(-100%);
transition: all 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.closingNavbarMobile {
  width: 100%; 
  background-color: rgba(92, 91, 91, 0.41);
  backdrop-filter: blur(2px);
  overflow: hidden;
  height: 100dvh;
}



.openNavbarLinks {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column; 
  overflow: auto;
  padding: 0;
  list-style: none;

  
   

    .LinkDirection {
      margin: 0px 16px 6px;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    line-height: 20px;
    border-radius: 8px;
    color: rgb(45, 41, 38); 
    text-decoration: none;
     
    font-size: 0.9rem; 
    } 

    .expand {
      margin: 0px 16px 6px;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    line-height: 20px;
    border-radius: 8px;
    color: rgb(45, 41, 38); 
    text-decoration: none;
     
    font-size: 0.9rem;
    justify-content: space-between;
    cursor: pointer;
    } 

    .expandactive {
      margin: 0px 16px 6px;
      padding: 8px;
      position: relative;
      display: flex;
      align-items: center;
      grid-gap: 4px;
      gap: 4px;
      line-height: 20px;
      border-radius: 8px;
      color: rgb(45, 41, 38); 
      text-decoration: none;
       
      font-size: 0.9rem;
      justify-content: space-between;
      cursor: pointer;
      background-color: rgb(246, 246, 246);
    }

    
    .expand:hover, .sizAu:hover, .poPds:hover {
      background-color: rgb(246, 246, 246);
    } 

 
}


.LinkDirection {
  margin: 0px 16px 6px;
padding: 8px;
position: relative;
display: flex;
align-items: center;
grid-gap: 4px;
gap: 4px;
line-height: 20px;
border-radius: 8px;
color: rgb(45, 41, 38); 
text-decoration: none;
 
font-size: 0.9rem; 
} 

.LinkDirection:hover {
  background-color: rgb(246, 246, 246);
} 

.opened {
  visibility: visible;
  transform: translateX(0%); 
  height: 100vh;
}
 
.dropdownnone {
  display: none;
}

.chevron {
  display: flex; 
  color: rgb(45, 41, 38);
}


.dpdZy {
  padding-left: 1rem;
}
 
.eUview {

  list-style: none;
  .sizAu {
    margin: 0px 16px 6px;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 4px; 
    gap: 4px;
    line-height: 20px;
    border-radius: 8px;
    color: rgb(45, 41, 38);
    text-decoration: none;
     
    font-size: 0.9rem;
  }
}

.irCp {
  height: 1px;
    background-color: rgb(224, 224, 224);
    margin: 8px 16px 24px;
}

.poPds {
  margin: 0px 16px 6px;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    line-height: 20px;
    border-radius: 8px;
    color: #D5A676; 
    text-decoration: none;
     
    font-size: 0.9rem;
    justify-content: space-between;
    cursor: pointer;
}

.pxthD {
  height: 2px;
    background-color: #D5A676;
    margin: 8px 16px 24px;
}

.tsfYx {
  -webkit-box-flex: 1;
    flex-grow: 1;
}


.f1rjtQs {
  padding: 16px;
  width: 100%;
  text-align: left;
  margin: 0 auto; 
}
 
 
.rgVuVs {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  padding: 8px;
  grid-gap: 4px;
  gap: 4px;
  line-height: 20px;
  border-radius: 8px;
  color: rgb(45, 41, 38);
  text-decoration: none;
   
  font-size: 0.9rem;
}


.j0rpss {
  position: relative;
  display: flex;
  padding: 8px;
  align-items: center; 
  grid-gap: 4px;
  gap: 4px;
  line-height: 20px;
  border-radius: 8px;
  color: rgb(45, 41, 38);
  text-decoration: none;
   
  font-size: 0.9rem;
}


.tiVles {
  display: flex;
  gap: 5px;
  position: relative;
  justify-content: center;
  line-height: 16px;
}

.f0nUIs {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 16px;
  height: 16px;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;


  img {
      position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  }
}
 
.rgVuVs:hover, .j0rpss:hover {
  color: rgb(254, 80, 0);
  background-color: rgb(246, 246, 246);
}


#summ98 {
  padding: 8px 20px;
}

@media only screen and (max-width: 600px) {
 .Menu { 
  width: 15.8rem;
 }
 }

